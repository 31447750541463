import * as yup from 'yup';
import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { Field } from 'formik';

export const createPpwAdjustmentValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  amount: yup.number().required('Amount is required'),
});

export const CreatePpwAdjustmentForm = () => {
  return (
    <>
      <Box direction="column" childGap="md">
        <Field component={FormikTextInput} label="Adjustment Name" name="name" id="name" isRequired />
        <Field
          component={FormikTextInput}
          label="Amount"
          name="amount"
          id="amount"
          type="number"
          suffix="$/w"
          isRequired
        />
      </Box>
    </>
  );
};
