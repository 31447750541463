import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useUploadDocumentMutation, useGetDocumentsByMultipleRequestsQuery } from '../../../services/documents';
import {
  Box,
  Button,
  Card,
  Modal,
  Spinner,
  FileUpload,
  SelectInput,
  toast,
  useBreakpoint,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { useLocation, useParams } from 'react-router-dom';
import {
  AllDocumentTypes,
  DocumentStatus,
  DocumentTypes,
  InstallDocumentTypes,
  documentTitles,
  ActivationDocumentTypes,
  ActivationPackagePhotoCategory,
  InterconnectionAgreementDocumentType,
} from 'types';
import { DocumentSortField } from 'types';
import RequirePermissions from '../../auth/requirePermissions';
import { DocumentListItem } from './DocumentListItem';
import { DocumentPreview, DocumentPreviewModal } from '../../documents/DocumentPreviewModal';
import { isErrorWithData } from '@/services/helpers';

type DocumentListProps = {
  documentTypeFilter: Array<AllDocumentTypes> | undefined;
  additionalDocumentsToFetch?: Array<{
    types: Array<AllDocumentTypes> | undefined;
    status?: Array<DocumentStatus>;
  }>;
  showDocumentActions?: boolean;
  allowArchive?: boolean;
  showTypeLabelOnItems?: boolean;
  showUploadButton?: boolean;
  className?: string;
  title?: string;
  baseCategory?: string;
  uploadContext?: string;
};

const createDocumentApiParams = (
  documentTypeFilter: Array<AllDocumentTypes> | undefined,
  additionalDocumentsToFetch?: Array<{
    types: Array<AllDocumentTypes> | undefined;
    status?: Array<DocumentStatus>;
  }>,
) => {
  const paramsToCallDocumentApi = [];
  const baseQuery = { sort: DocumentSortField.ID_DESC };
  if (documentTypeFilter) {
    paramsToCallDocumentApi.push({ ...baseQuery, documentTypeFilter });
  }
  if (additionalDocumentsToFetch?.length) {
    additionalDocumentsToFetch.forEach((additionalDocument) => {
      paramsToCallDocumentApi.push({
        ...baseQuery,
        documentTypeFilter: additionalDocument.types,
        ...(additionalDocument.status && { documentStatusFilter: additionalDocument.status }),
      });
    });
  }
  return paramsToCallDocumentApi;
};

export function DocumentList({
  documentTypeFilter = [],
  additionalDocumentsToFetch,
  showDocumentActions = true,
  allowArchive = true,
  showTypeLabelOnItems = true,
  showUploadButton = true,
  className,
  title,
  baseCategory,
  uploadContext,
}: DocumentListProps) {
  const { id } = useParams<{ id: any }>();
  const queryParams = new URLSearchParams(useLocation().search);
  const scrollTo = queryParams.get('scrollTo');
  const { data: items = [], isLoading: isDocumentsLoading } = useGetDocumentsByMultipleRequestsQuery({
    accountId: id,
    apiFilters: createDocumentApiParams(documentTypeFilter, additionalDocumentsToFetch),
  });
  const documents = [...items];
  const { isPhone } = useBreakpoint();
  const { isOpen: isUploadModal, handleClose: handleUploadModalClose, handleOpen: openUploadModal } = useOpenClose();

  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();

  const isLoading = isDocumentsLoading;

  const sectionRefs = useMemo(
    () => ({
      documents: createRef(),
    }),
    [],
  ) as any;

  useEffect(() => {
    if (scrollTo) {
      sectionRefs[scrollTo]?.current?.scrollIntoView();
    }
  }, [scrollTo, sectionRefs]);

  // These values must match the DocumentTypes enum in the API.
  const documentTypeOptions = useMemo(() => {
    const allDocuments = {
      ...DocumentTypes,
      ...InstallDocumentTypes,
      ...ActivationDocumentTypes,
      ...ActivationPackagePhotoCategory,
      ...InterconnectionAgreementDocumentType,
    };
    const documentTypes = Object.keys(allDocuments).map((key) => {
      return { value: key, label: documentTitles[key as AllDocumentTypes] };
    });
    if (documentTypeFilter.length > 0) {
      return documentTypes.filter((dt) => documentTypeFilter.includes(dt.value as AllDocumentTypes));
    }
    return documentTypes;
  }, [documentTypeFilter]);

  const [files, setFiles] = useState<FileList | undefined>();

  const [type, setType] = useState<{ value: string; label: string } | undefined>();

  useEffect(() => {
    if (documentTypeOptions.length === 1) {
      setType(documentTypeOptions[0]);
    }
  }, [documentTypeOptions, setType]);

  const onFileChange = useCallback(
    (event: any) => {
      setFiles(event.target?.files);
    },
    [setFiles],
  );

  const closeUploadModal = useCallback(() => {
    setFiles(undefined);
    handleUploadModalClose();
  }, [setFiles, handleUploadModalClose]);

  const onTypeChange = useCallback((event: any) => setType(event.target.value), [setType]);

  const handleUpload = async () => {
    const data = new FormData();
    data.append('grouped', 'true');

    if (uploadContext) {
      data.append('context', uploadContext);
    }

    if (type && 'value' in type) {
      data.append('type', type.value as string);
    }
    if (!!files) {
      for (let i = 0; i < files.length; i++) {
        data.append(`files[${i}]`, files[i]);
      }
    }
    try {
      await uploadDocument({ accountId: id, formData: data, invalidateAllTags: false }).unwrap();
      toast.success('Document uploaded');
      closeUploadModal();
    } catch (e: any) {
      if (e.status === 413 || e.originalStatus === 413) {
        toast.error('File size too large. Please upload files smaller than 125MB.');
      } else if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        toast.error('An error occurred while uploading documents');
      }
    }
  };

  return (
    <>
      <Modal isOpen={isUploadModal} onDismiss={closeUploadModal} maxWidth="4xl" ariaLabelledBy="uploadDocHeader">
        <Modal.Header id="uploadDocHeader" title="Upload Documents" onDismiss={closeUploadModal} />
        <Modal.Body background="secondary" childGap="lg">
          <SelectInput
            size="md"
            id="required"
            label="Document Type"
            value={type}
            onChange={onTypeChange}
            options={documentTypeOptions}
            isRequired
            isDisabled={isUploadingDocument || documentTypeOptions.length === 1}
            menuPortalTarget={document.body}
          />
          <FileUpload
            buttonText="Choose File(s)"
            name="files"
            labelText="Choose File(s)"
            id="files"
            accept="image/*,.pdf,.dxf"
            variant="secondary"
            tone="neutral"
            multiple
            required
            requiredIndicator=""
            onChange={onFileChange}
            isDisabled={isUploadingDocument}
            files={files}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" tone="neutral" isDisabled={isLoading} onClick={closeUploadModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            isLoading={isUploadingDocument}
            onClick={handleUpload}
            isDisabled={!files || !files.length}
          >
            Upload Document
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className={className}>
        <Box
          childGap="lg"
          padding="lg"
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          ref={sectionRefs.documents}
        >
          <Box as="h3" fontWeight="medium" fontSize="md" direction="row" gap="md" alignItems="center">
            <Box>{title ? `${title}` : 'Documents'}</Box>
            {documents?.length > 1 && <Box fontSize="md" fontWeight="light">{`${documents?.length} uploads`}</Box>}
          </Box>
          {showUploadButton && (
            <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
              <Button size="sm" variant="primary" tone="neutral" iconPrefix="upload" onClick={openUploadModal}>
                {!isPhone && 'Upload'}
              </Button>
            </RequirePermissions>
          )}
        </Box>
        <List
          isLoading={isLoading}
          items={documents as any}
          showDocumentActions={showDocumentActions}
          showTypeLabelOnItems={showTypeLabelOnItems}
          allowArchive={allowArchive}
          baseCategory={baseCategory}
          title={title}
        />
      </Card>
    </>
  );
}

interface ListProps {
  isLoading: boolean;
  items: any[];
  showDocumentActions?: boolean;
  showTypeLabelOnItems?: boolean;
  allowArchive?: boolean;
  baseCategory?: string;
  title?: string;
}

function List({
  isLoading,
  items = [],
  showDocumentActions = true,
  showTypeLabelOnItems,
  allowArchive = true,
  baseCategory,
  title,
}: ListProps) {
  const totalCount = items?.length || 0;
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
  const documents: DocumentPreview[] = useMemo(
    () =>
      items?.flatMap((item) =>
        item.files.map((file: any, index: number) => ({
          id: `${item.id}${index ? '-' + index : ''}`,
          url: file?.viewUrls?.[0]?.url,
          contentType: file?.contentType,
          fileName: file?.originalName,
          createdAt: item.meta.createdAt,
          type: item.type,
        })),
      ),
    [items],
  );
  const [targetDoc, setTargetDoc] = useState<DocumentPreview>(documents[0]);

  const togglePreviewModal = useCallback(
    (id: string) => {
      setTargetDoc(documents.find((doc) => doc.id === id) ?? documents[0]);
      setIsFilePreviewModalOpen((prev) => !prev);
    },
    [documents],
  );

  return (
    <>
      <Card.Section padding="0">
        {totalCount > 0 ? (
          <>
            <DocumentPreviewModal
              targetDoc={targetDoc}
              setTargetDoc={setTargetDoc}
              isFilePreviewModalOpen={isFilePreviewModalOpen}
              documentList={documents}
              closeFilePreviewModal={() => setIsFilePreviewModalOpen(false)}
              title={title}
              baseCategory={baseCategory}
            />
            {items.map((item) => {
              return (
                <DocumentListItem
                  item={item}
                  key={item.id}
                  showDocumentActions={showDocumentActions}
                  showTypeLabel={showTypeLabelOnItems}
                  allowArchive={allowArchive}
                  baseCategory={baseCategory}
                  onClick={() => togglePreviewModal(item.id)}
                />
              );
            })}
          </>
        ) : (
          <Box
            display="block"
            textAlign="center"
            padding="lg"
            childGap="xl"
            borderColor="separator"
            borderWidth="xs 0 0 0"
          >
            {isLoading ? (
              <Spinner size="lg" />
            ) : (
              <>
                <Box fontSize="sm" color="body-secondary">
                  No documents exist for this account
                </Box>
              </>
            )}
          </Box>
        )}
      </Card.Section>
    </>
  );
}
