import FormSection from '../Forms/FormSection';
import { InstallDocumentTypes } from 'types';
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';

export const InstallLoadProof = () => {
  return (
    <FormSection title="Load Calculation" description={'Upload evidence of the load calculation for this site.'}>
      <DocumentList
        documentTypeFilter={[InstallDocumentTypes.proofOfLoad]}
        title="Proof of Load Calculation"
        showTypeLabelOnItems={false}
        baseCategory={InstallDocumentTypes.proofOfLoad}
      />
    </FormSection>
  );
};
