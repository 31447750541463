import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Toggle, toast } from '@palmetto/palmetto-components';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import PageHeader from '../PageHeader';
import FormSection from '../Forms/FormSection';
import { useGetAccountWithTokenQuery } from '@/services/accounts';
import { useGetDisclosuresByTypeQuery } from '@/services/disclosures';
import { createApplicationRequest } from '@/helpers/createApplicationRequest';
import { useCreateApplicationMutation, useGetApplicationsQuery } from '@/services/applications';
import { AddressFormFields } from '../AddressFormFields/AddressFormFields';
import { MainContainer } from '@/components';
import { ApplicantInformation } from './ApplicantInformation';
import { isErrorWithData } from '@/services/helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useInviteToken } from '../auth/useInviteToken';
import { DisclosureCard } from '@/components/Disclosures/DisclosureCard';
import { ApplicationDocumentWithAdditionalApplicantData, DisclosureType, FinancingDecision } from 'types';
import { Application } from '@/types/Applications';

const applicantSchema = yup.object().shape({
  firstName: yup.string().required('First Name is a required field.'),
  middleName: yup.string().optional().nullable(),
  lastName: yup.string().required('Last Name is a required field.'),
  phoneNumber: yup.string().required('Phone Number is a required field.'),
  email: yup.string().email('Invalid email address.').required('Email is a required field.'),
  ssn: yup.string().matches(/^[0-9]{9}$|^[0-9]{4}$/, 'SSN value must match format 555555555 or 5555'),
});

const applicationSchema = yup.object().shape({
  addAdditionalApplicant: yup.boolean().optional().nullable(),
  secondary: yup.object().when('addAdditionalApplicant', {
    is: true,
    then: () => applicantSchema,
  }),
  primary: applicantSchema,

  address1: yup.string().required('Address is a required field.'),
  address2: yup.string().optional().nullable(),
  city: yup.string().required('City is a required field.'),
  state: yup.string().required('State is a required field.'),
  zip: yup.string().required('Zip is a required field.'),
  creditApplicationDisclosure: yup
    .boolean()
    .oneOf([true], 'You must agree to the credit application disclosure.')
    .required(),
});

// Infertype does not seem to pick up the when conditional
export type ApplicationFormSchema = yup.InferType<typeof applicationSchema> & {
  secondary?: yup.InferType<typeof applicantSchema>;
};

export function ApplicationForm() {
  const { id } = useParams<{ id: any }>();
  const { inviteToken } = useInviteToken();

  const { data: accountData, isLoading: isAccountInfoLoading }: any = useGetAccountWithTokenQuery({
    id,
    inviteToken,
  });
  const { data: disclosures, isLoading: isDisclosuresLoading } = useGetDisclosuresByTypeQuery({
    type: DisclosureType.creditApplication,
    inviteToken,
  });
  const { data: applications = [], isLoading: isApplicationLoading } = useGetApplicationsQuery({
    accountId: id,
    inviteToken
  });

  const [createApplication] = useCreateApplicationMutation();
  const navigate = useNavigate();
  const [showAdditionalApplicant, setShowAdditionalApplicant] = useState(false);
  const { isCosignerEnabled, blockAdditionalCreditApplications } = useFlags();
  const isLoading = isAccountInfoLoading || isDisclosuresLoading || isApplicationLoading;
  const hasApprovedApplication = applications?.some(
    (app: ApplicationDocumentWithAdditionalApplicantData) =>
      app.status && [FinancingDecision.approved, FinancingDecision.approvedWithStipulations].includes(app.status),
  );
  const shouldBlockCreditApplication = hasApprovedApplication && blockAdditionalCreditApplications;
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const { redirectUrl } = await createApplication({
        application: createApplicationRequest(accountData, disclosures, values),
        accountId: accountData.id,
        inviteToken,
      }).unwrap();
      toast.success('Application submitted successfully');
      redirectUrl ? (window.location.href = redirectUrl) : navigate(`/accounts/${id}`);
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error submitting application');
      }
    } finally {
      setSubmitting(false);
    }
  };
  const initialValues = useMemo(() => {
    if (isLoading || !accountData) return;

    const initialApplicantValues: any = {
      primary: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        ssn: '',
      },
      secondary: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        ssn: '',
      },
    };
    accountData?.applicants?.forEach((applicant: any) => {
      initialApplicantValues[applicant.type] = {
        ...applicant,
        firstName: applicant.firstName,
        middleName: applicant.middleName,
        lastName: applicant.lastName,
        email: applicant.email,
        phoneNumber: applicant.phoneNumber,
        ssn: '',
      };
    });

    if (accountData.applicants.length > 1) {
      setShowAdditionalApplicant(true);
      initialApplicantValues.addAdditionalApplicant = true;
    }

    return {
      ...initialApplicantValues['primary'].address,
      creditApplicationDisclosure: false,
      ...initialApplicantValues,
    };
  }, [accountData, isLoading]);
  return (
    <>
      <Helmet>
        <title>Credit Check Application</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          title="Credit Check Application"
          description="Soft credit inquiry with no impact to homeowner credit score"
        />
        {shouldBlockCreditApplication && (
          <Alert
            variant="warning"
            title="Credit Underwriting Is Approved"
            message="Credit underwriting for this Account has already been approved. Additional applications are not allowed."
            hasIcon
          />
        )}
        <Box padding={{ base: '0 lg', tablet: '0' }}>
          <Formik
            initialValues={initialValues}
            validationSchema={applicationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form noValidate>
                <FormSection
                  title="Applicant Information"
                  description="At least one applicant must be on property title"
                >
                  <ApplicantInformation isSubmitting={isSubmitting} isLoading={isLoading} prefix="primary" />
                </FormSection>
                {isCosignerEnabled && (
                  <FormSection
                    title="Additional Applicant"
                    description={
                      <Box as="ul" color="body-secondary" padding="xs 0 0 md" childGap="xs">
                        <li>Must be a spouse or legal partner</li>
                        <li>Must use a different email address than the primary applicant</li>
                        <li>
                          Either applicant can be credit qualified, and at least one applicant must appear on
                          property title report
                        </li>
                      </Box>
                    }
                  >
                    <Toggle
                      id="addAdditionalApplicant"
                      label="Additional Applicant"
                      isChecked={showAdditionalApplicant}
                      onChange={() => {
                        setShowAdditionalApplicant(!showAdditionalApplicant);
                        setFieldValue('addAdditionalApplicant', !showAdditionalApplicant);
                      }}
                    />
                    {showAdditionalApplicant && (
                      <ApplicantInformation isSubmitting={isSubmitting} isLoading={isLoading} prefix="secondary" />
                    )}
                  </FormSection>
                )}
                <FormSection title="Address Information">
                  <AddressFormFields isSubmitting={isSubmitting} placeHolderId="applicantFormAddress" />
                </FormSection>
                <FormSection title="Disclosures">
                  {disclosures &&
                    disclosures.length > 0 &&
                    disclosures.map((disclosure: any) => (
                      <DisclosureCard
                        disclosure={disclosure}
                        key={disclosure.id}
                        disclosureProperty="creditApplicationDisclosure"
                        isLoading={isLoading}
                      />
                    ))}
                </FormSection>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  alignItems={{
                    base: 'stretch',
                    tablet: 'flex-end',
                  }}
                  justifyContent={{
                    tablet: 'flex-end',
                  }}
                  childGap="sm"
                  style={{ flexShrink: 0 }}
                  padding="lg 0 0 0"
                >
                  <Button
                    as="a"
                    href={`/accounts/${id}`}
                    variant="secondary"
                    tone="neutral"
                    size="md"
                    isDisabled={isLoading}
                    isLoading={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    variant="primary"
                    type="submit"
                    isDisabled={isLoading || shouldBlockCreditApplication}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </MainContainer>
    </>
  );
}
