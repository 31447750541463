import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  FileUpload,
  Modal,
  toast,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { StatusIcon } from '../StatusIcon';
import { DocumentDocument, ProgramType, UnderwritingDocumentTypes } from 'types';
import { useGetDocumentsQuery, useUploadDocumentMutation } from '../../services/documents';
import { DocumentItem } from './DocumentItem';
import { AccountRequirementStatus } from '../../types/Milestone';
import { useGetQueuesByAccountIdAndDocumentTypeQuery } from '../../services/queues';
import { isErrorWithData } from '@/services/helpers';

interface IdentityVerificationProps {
  status: AccountRequirementStatus;
  creditStatus: AccountRequirementStatus;
  programType: ProgramType;
}

export function IdentityVerification({ status, creditStatus, programType }: IdentityVerificationProps) {
  const { isOpen, handleClose, handleOpen: openUploadModal } = useOpenClose();
  const [files, setFiles] = useState<FileList | undefined>();
  const { id } = useParams<{ id: any }>();

  const { data: documents, isLoading: isDocumentsLoading } = useGetDocumentsQuery({
    accountId: id,
    documentTypeFilter: [UnderwritingDocumentTypes.identityVerification],
  });
  const { data: queueItems, isLoading: isGetQueueByAccountIdLoading } = useGetQueuesByAccountIdAndDocumentTypeQuery({
    accountId: id,
    type: UnderwritingDocumentTypes.identityVerification,
  });
  const isLoading = isDocumentsLoading || isGetQueueByAccountIdLoading;
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();

  const documentIdToQueueIdMap = useMemo(() => {
    if (isGetQueueByAccountIdLoading || !queueItems || queueItems.length === 0) return {};
    const documentIdToQueueId: any = {};
    for (const item of queueItems) {
      for (const id of item.documentIds) {
        documentIdToQueueId[id] = item;
      }
    }
    return documentIdToQueueId;
  }, [queueItems, isGetQueueByAccountIdLoading]);

  const onFileChange = (event: any) => {
    setFiles(event.target?.files);
  };

  const closeUploadModal = () => {
    setFiles(undefined);
    handleClose();
  };

  const handleFileUpload = async () => {
    if (!!files) {
      const data = new FormData();
      data.append('grouped', 'true');
      data.append('type', UnderwritingDocumentTypes.identityVerification);

      for (let i = 0; i < files.length; i++) {
        data.append(`files[${i}]`, files[i]);
      }

      try {
        await uploadDocument({ accountId: id, formData: data, invalidateAllTags: true }).unwrap();
        closeUploadModal();
        toast.success(`Identity verification uploaded`);
      } catch (e: any) {
        if (e.status === 413 || e.originalStatus === 413) {
          toast.error('File size too large. Please upload files smaller than 125MB.');
        } else if (isErrorWithData(e)) {
          toast.error(e.data.message);
        } else {
          toast.error('An error occurred while uploading documents');
        }
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={closeUploadModal}
        maxWidth="4xl"
        ariaLabelledBy="uploadIdentityVerificationHeader"
      >
        <Modal.Header
          id="uploadIdentityVerificationHeader"
          title="Upload Proof of Identity"
          onDismiss={closeUploadModal}
        />
        <Modal.Body background="secondary" childGap="md">
          <Alert
            hasIcon
            variant="info"
            message="Verification is faster if the customer submits proof of identity via their app account"
          />
          <Box>Acceptable docs:</Box>
          <Box as="ul" childGap="xs">
            <li>Drivers license or other state issued ID card</li>
            <li>Passport or passport card</li>
            <li>Resident permit ID or green card</li>
          </Box>
          <FileUpload
            buttonText="Choose File(s)"
            name="files"
            labelText="Choose File(s)"
            id="files"
            accept="image/*,.pdf"
            variant="secondary"
            tone="neutral"
            multiple
            size="md"
            required
            requiredIndicator=""
            onChange={onFileChange}
            isDisabled={isUploadingDocument}
            files={files}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" tone="neutral" isDisabled={isUploadingDocument} onClick={closeUploadModal}>
            Cancel
          </Button>
          <Button isLoading={isUploadingDocument} onClick={handleFileUpload} isDisabled={!files || !files.length}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      <Card.Section childGap="md">
        <Box childGap="lg" alignItems="center" direction="row" justifyContent="space-between">
          <Box direction="row" alignItems="center">
            <Box padding="0 xs 0 0">
              <StatusIcon status={status} size="md" />
            </Box>
            <Box as="h3" fontWeight="medium" fontSize="md">
              Identity Verification
            </Box>
          </Box>
          {creditStatus !== 'completed' && programType !== ProgramType.doePr ? (
            <Box fontWeight="medium" fontSize="sm" color="body-secondary">
              Pending Credit Application
            </Box>
          ) : status !== 'completed' ? (
            <Box>
              <Button
                size="sm"
                style={{ width: 'fit-content' }}
                onClick={() => openUploadModal()}
                variant="secondary"
                tone="neutral"
              >
                Upload Proof of Identity
              </Button>
            </Box>
          ) : (
            <Badge message="Verified" variant="success" />
          )}
        </Box>
        {documents?.length && !isLoading ? (
          <Box childGap="lg" display="block">
            {documents?.map((doc: DocumentDocument) => {
              const docId = doc?.id?.toString();
              const queueItem = docId ? documentIdToQueueIdMap[docId] : undefined;
              return <DocumentItem key={docId} item={doc} showDocumentActions={true} queueItem={queueItem} />;
            })}
          </Box>
        ) : null}
      </Card.Section>
    </>
  );
}
