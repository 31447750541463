import {
  Box,
  Button,
  FileUpload,
  Modal,
  SelectInputNative,
  TextareaInput,
  toast,
  useBreakpoint,
} from '@palmetto/palmetto-components';
import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { FlagDocumentTypes, FlagReasons } from 'types';
import { QueueFlagsContext } from './QueueFlagsContext';
import { useUploadDocumentMutation } from '../../services/documents';
import RequirePermissions from '../auth/requirePermissions';
import { trimFileNames } from '@/helpers/trimFileNames';
import { isErrorWithData } from '@/services/helpers';

interface AddFlagModalProps {
  accountId: string;
  isOpen: boolean;
  onDismiss: () => void;
  dataKey: string;
  fileName?: string;
  baseCategory?: string;
  showUploadButton?: boolean;
  type?: string;
}

export const AddFlagModal = ({
  accountId,
  isOpen,
  onDismiss,
  dataKey,
  fileName,
  baseCategory,
  showUploadButton = false,
  type
}: AddFlagModalProps) => {
  const [note, setNote] = useState('');
  const [reason, setReason] = useState('');
  const { addFlag, isLoading } = useContext(QueueFlagsContext);
  const [uploadedDocumentId, setUploadedDocumentId] = useState<string>('');
  const [uploadedDocumentName, setUploadedDocumentName] = useState<string>('');
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();
  const { isPhone } = useBreakpoint();

  const setNoteText = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  }, []);
  const setReasonValue = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    setReason(event.target.value);
  }, []);
  const reasonOptions = useMemo(() => {
    return Object.values(FlagReasons).map((value) => ({ value: value, label: value }));
  }, []);

  const hide = useCallback(() => {
    setNote('');
    setReason('' as FlagReasons);
    onDismiss();
  }, [onDismiss]);

  const save = useCallback(async () => {
    if (isLoading) {
      return;
    }
    try {
      await addFlag({
        dataKey,
        fileName,
        note,
        reason: reason as FlagReasons,
        baseCategory,
        fileUpload: uploadedDocumentId,
        type
      });
    } catch (e) {
      console.error('Error submitting flag: ', {
        e,
      });
    }
    setUploadedDocumentId('');
    setUploadedDocumentName('');
    hide();
  }, [addFlag, dataKey, fileName, note, reason, baseCategory, uploadedDocumentId, hide]);

  const handleFileUpload = useCallback(
    async (event: any) => {
      const files = event.target?.files;
      if (!!files) {
        const data = new FormData();
        data.append('type', FlagDocumentTypes.flag);

        for (let i = 0; i < files.length; i++) {
          data.append(`files[${i}]`, files[i]);
        }

        try {
          const result = (await uploadDocument({
            accountId,
            formData: data,
            invalidateAllTags: false,
          }).unwrap()) as any;
          setUploadedDocumentId(result[0].id);
          setUploadedDocumentName(result[0].files[0].originalName);
          toast.success(`Document uploaded`);
        } catch (e: any) {
          if (e.status === 413 || e.originalStatus === 413) {
            toast.error('File size too large. Please upload files smaller than 125MB.');
          } else if (isErrorWithData(e)) {
            toast.error(e.data.message);
          } else {
            toast.error('An error occurred while uploading documents');
          }
        }
      }
    },
    [accountId, uploadDocument],
  );

  const truncatedFileName = trimFileNames(fileName, dataKey);

  return (
    <Modal isOpen={isOpen} maxWidth="4xl" ariaLabelledBy="addFlagHeader" onDismiss={hide}>
      <Modal.Header id="addFlagHeader" title={`Flag ${truncatedFileName}`} onDismiss={hide} />
      <Modal.Body background="secondary" childGap="lg">
        <Box childGap="md">
          <SelectInputNative
            id="reason"
            label="Reason"
            value={reason}
            onChange={setReasonValue}
            options={reasonOptions}
            isRequired
          />
          <TextareaInput id="note" label="Notes (optional)" value={note} onChange={setNoteText} rows={3} />
          {showUploadButton && (
            <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
              <Box direction="row" childGap="md" alignItems="center">
                {isUploadingDocument ? (
                  <Button
                    variant="secondary"
                    tone="neutral"
                    iconPrefix="upload"
                    isDisabled={true}
                    isLoading={isUploadingDocument || isLoading}
                    size="sm"
                  >
                    Upload
                  </Button>
                ) : (
                  <FileUpload
                    labelText="Upload"
                    id={`upload-${FlagDocumentTypes.flag}-photos`}
                    name={`${FlagDocumentTypes.flag}-files`}
                    accept="image/*,.pdf"
                    size="sm"
                    onChange={handleFileUpload}
                    buttonText={!isPhone ? 'Upload' : null}
                    onClick={(event: any) => event.stopPropagation()}
                    isDisabled={isUploadingDocument || isLoading}
                  />
                )}
                {uploadedDocumentId && uploadedDocumentName && (
                  <Box fontSize="xs">{uploadedDocumentName} uploaded</Box>
                )}
              </Box>
            </RequirePermissions>
          )}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          as="button"
          onClick={hide}
          variant="secondary"
          tone="neutral"
          size="md"
          disabled={isUploadingDocument || isLoading}
        >
          Cancel
        </Button>
        <Button size="md" type="submit" onClick={save} color="danger" disabled={isUploadingDocument || isLoading}>
          Flag Item
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
