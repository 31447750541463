import { Box, Button, Card, Column, Table, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import {
  AdderType,
  ApprovedVendorSettingValue,
  ConstructionAdderType,
  ProgramType,
  VendorEquipmentTypes,
  adderMap,
} from 'types';
import { useLazyGetConstructionAddersQuery } from '../../../services/pricing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { currencyFormatter, kwhPriceFormatter } from '../../../helpers/currencyFormatter';
import { CreateKwhAdderModal } from './CreateKwhAdderModal';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../../auth/requirePermissions';
import { EditAdderModal } from './EditAdderModal';
import { CreateBackupBatteryModal } from './CreateBackupBatteryAdderModal';
import { useGetApprovedVendorsByTypeQuery } from '../../../services/approvedVendors';
import { CreateBackupBatteryPriceCapModal } from './CreateBackupBatteryPriceCapModal';
import { CreateMaxAmountAdderModal } from './CreateMaxAmountAdderModal';

export const Adder = () => {
  const { isPhone } = useBreakpoint();
  const [selected, setSelected] = useState<any>(null);
  const { programType = ProgramType.solar, adderType } = useParams<{ programType: string; adderType: string }>();
  const [trigger, { data = [], isLoading, isFetching }] = useLazyGetConstructionAddersQuery();
  const { data: avlStorage } = useGetApprovedVendorsByTypeQuery({
    equipmentType: VendorEquipmentTypes.storage,
    programType: programType as ProgramType,
  });
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const handleClick = useCallback(
    (row: any) => {
      setSelected(row);
      openEditModal();
    },
    [openEditModal],
  );

  const columnConfig = useMemo(() => {
    switch (adderType) {
      case ConstructionAdderType.backupBatteryPriceCap:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          // rest of columns come from avl batteries
          ...(avlStorage?.data?.map((storage: ApprovedVendorSettingValue) => ({
            heading: (
              <Box>
                <Box>{storage.manufacturer}</Box>
                <Box>{storage.modelNumber}</Box>
              </Box>
            ),
            dataKey: storage.id,
            render: (_: any, row: any) => {
              return `$${row?.backupBatteryPriceCaps?.find((b: any) => b.batteryId === storage.id)?.maxPrice ?? ''}`;
            },
          })) ?? []),
        ];
      case AdderType.backupBattery:
        switch (programType) {
          case ProgramType.doePr:
            return [
              {
                heading: 'Name',
                dataKey: 'name',
                render: (_: any, row: any) => (
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                    {row.name}
                  </span>
                ),
              },
              {
                heading: '0% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0);
                  return record?.value ?? '';
                },
              },
              {
                heading: '1% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.01);
                  return record?.value ?? '';
                },
              },
            ];
          default:
            return [
              {
                heading: 'Name',
                dataKey: 'name',
                render: (_: any, row: any) => (
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                    {row.name}
                  </span>
                ),
              },
              {
                heading: '0% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0);
                  return record?.value ?? '';
                },
              },
              {
                heading: '0.99% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.0099);
                  return record?.value ?? '';
                },
              },
              {
                heading: '1.99% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.0199);
                  return record?.value ?? '';
                },
              },
              {
                heading: '2.99% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.0299);
                  return record?.value ?? '';
                },
              },
              {
                heading: '3.5% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.035);
                  return record?.value ?? '';
                },
              },
            ];
        }

      case AdderType.solarReadiness:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          { heading: 'Max Amount', dataKey: 'maxAmount', render: (cell: any) => currencyFormatter.format(cell) },
        ];
      default:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          { heading: '$/kwh', dataKey: 'kwhAdderBand', render: (cell: any) => kwhPriceFormatter.format(cell) },
        ];
    }
  }, [adderType, avlStorage, handleClick]);

  useEffect(() => {
    if (adderType) {
      trigger({ type: adderType, programType });
    }
  }, [adderType, trigger]);

  return (
    <>
      {adderType === AdderType.backupBattery ? (
        <CreateBackupBatteryModal isModalOpen={isModalOpen} closeModal={closeModal} adderType={adderType} />
      ) : adderType === AdderType.backupBatteryPriceCap ? (
        <CreateBackupBatteryPriceCapModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          adderType={adderType}
          programType={programType as ProgramType}
        />
      ) : adderType === AdderType.solarReadiness ? (
        <CreateMaxAmountAdderModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          adderType={adderType}
          programType={programType as ProgramType}
        />
      ) : (
        <CreateKwhAdderModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          adderType={adderType}
          programType={programType as ProgramType}
        />
      )}
      <EditAdderModal
        isModalOpen={isEditModalOpen}
        closeModal={closeEditModal}
        adderId={selected?.id}
        adderName={selected?.name}
      />
      <Card padding="sm">
        <Card.Header>
          <Box direction="row" justifyContent="space-between">
            <Box fontSize="md" fontWeight="medium">
              {adderMap[programType as ProgramType][adderType as AdderType]}
            </Box>
            <RequirePalmettoFinanceUser>
              <RequirePermissions permissions={['pricingAdmin']}>
                <Button
                  as="button"
                  variant="primary"
                  iconPrefix="add"
                  size={{ base: 'sm', desktop: 'md' }}
                  aria-label="create adder"
                  onClick={openModal}
                >
                  {!isPhone && 'Option'}
                </Button>
              </RequirePermissions>
            </RequirePalmettoFinanceUser>
          </Box>
        </Card.Header>
        <Table rowKey="id" columns={columnConfig as Column[]} rows={data} isLoading={isLoading || isFetching} />
      </Card>
    </>
  );
};
