import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Organizations } from './settings/organizations/Organizations';
import { Create } from './settings/organizations/CreateOrganization';
import { OrganizationDetails } from './settings/organizations/Organization';
import { EditOrganization } from './settings/organizations/EditOrganization';
import { EditPaymentSchedule } from './settings/organizations/Payments/EditPaymentSchedule';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function Settings() {
  const { enablePayouts = false } = useFlags();

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Routes>
        <Route path="organizations" element={<Organizations />} />
        <Route path="organizations/new" element={<Create />} />
        <Route path="organizations/:alias/*" element={<OrganizationDetails />} />
        <Route path="organizations/:alias/edit" element={<EditOrganization />} />
        {enablePayouts && <Route path="organizations/:alias/payments/edit" element={<EditPaymentSchedule />} />}
        <Route path="*" element={<Navigate to="/settings/organizations" />} />
      </Routes>
    </>
  );
}
