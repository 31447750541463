import { Helmet } from 'react-helmet';
import { generatePath, matchPath, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Box, SelectInput } from '@palmetto/palmetto-components';
import PageHeader from '../PageHeader';
import { MainContainer } from '../MainContainer';
import { PriceSheets } from './PriceSheets/PriceSheets';
import { Mappings } from './Mappings/Mappings';
import { Adders } from './Adders/Adders';
import { Holdbacks } from './Holdbacks/Holdbacks';
import { Modifiers } from './TaxCreditModifiers/Modifiers';
import { PricingHistory } from './History/History';
import { useHasEnabledNonAnonFlag } from '../FlagGuard/Hook';
import { ProgramTypeLabels } from 'types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const baseRoute = '/admin/pricing/:programType';
const routes = {
  priceSheets: 'price-sheets',
  adders: 'adders/:adderType?',
  modifiers: 'modifiers/:modifierType?',
  holdbacks: 'holdbacks',
  history: 'history',
};

export function Pricing() {
  const { checkFlags: showPricingHistory } = useHasEnabledNonAnonFlag(['showPricingHistory']);
  const isPricingHistoryEnabled = showPricingHistory();
  const programTypesOptions = Object.entries(ProgramTypeLabels).map(([key, value]) => ({
    label: value,
    key,
    value,
  }));
  const [programType, setProgramType] = useState(programTypesOptions[0]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const { programType: urlProgramType = '' } = params;
    const lastProgramType =
      programTypesOptions.find((option) => option.key === urlProgramType) || programTypesOptions[0];
    setProgramType(lastProgramType);
    const pathPattern =
      Object.values(routes).find((pattern: string) => matchPath(`${baseRoute}/${pattern}`, location.pathname)) || '';
    const path = generatePath(`${baseRoute}/${pathPattern}`, { ...params, programType: urlProgramType });
    navigate(path);
  }, []);

  useEffect(() => {
    const { programType: urlProgramType = '' } = params;
    if (programType.key !== urlProgramType) {
      const lastProgramType =
        programTypesOptions.find((option) => option.key === urlProgramType) || programTypesOptions[0];
      setProgramType(lastProgramType);
    }
  }, [params]);

  const handleProgramTypeChange = (event: any) => {
    setProgramType(event.target.value);
    const pathPattern =
      Object.values(routes).find((pattern: string) => matchPath(`${baseRoute}/${pattern}`, location.pathname)) || '';
    const path = generatePath(`${baseRoute}/${pathPattern}`, { ...params, programType: event.target.value.key });
    navigate(path);
  };
  return (
    <>
      <Helmet>
        <title>Pricing</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <Box maxWidth="2xl">
          <SelectInput
            id="required"
            label="Program Type"
            value={programType}
            onChange={handleProgramTypeChange}
            options={programTypesOptions}
            menuPortalTarget={document.body}
          />
        </Box>
        <PageHeader title={`${programType.label} Pricing`}></PageHeader>
        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator">
          <NavLink to={''} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Mappings
              </Box>
            )}
          </NavLink>
          <NavLink to={`price-sheets`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Price Sheets
              </Box>
            )}
          </NavLink>

          {programType.label !== ProgramTypeLabels.hvac && (
            <NavLink to={`adders`} style={{ textDecoration: 'none', fontWeight: '500' }}>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Construction Adders
                </Box>
              )}
            </NavLink>
          )}
          {programType.label === ProgramTypeLabels.solar && (
            <>
              <NavLink to={`modifiers`} style={{ textDecoration: 'none', fontWeight: '500' }}>
                {({ isActive }) => (
                  <Box
                    as="span"
                    padding="md"
                    className={isActive ? 'tab-active' : ''}
                    hover={{ color: 'primary' }}
                    style={{ transitionDuration: '0s' }}
                  >
                    Modifiers
                  </Box>
                )}
              </NavLink>
              <NavLink to={`holdbacks`} style={{ textDecoration: 'none', fontWeight: '500' }}>
                {({ isActive }) => (
                  <Box
                    as="span"
                    padding="md"
                    className={isActive ? 'tab-active' : ''}
                    hover={{ color: 'primary' }}
                    style={{ transitionDuration: '0s' }}
                  >
                    Holdbacks
                  </Box>
                )}
              </NavLink>
            </>
          )}
          {isPricingHistoryEnabled && (
            <NavLink to={'history'} style={{ textDecoration: 'none', fontWeight: '500' }}>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  History
                </Box>
              )}
            </NavLink>
          )}
        </Box>
        <Routes>
          <Route path="/" element={<Mappings />} />
          <Route path="/price-sheets/" element={<PriceSheets />} />
          <Route path="/adders/:adderType?" element={<Adders />} />
          {programType.label === ProgramTypeLabels.solar && (
            <>
              <Route path="/modifiers/:modifierType?/*" element={<Modifiers />} />
              <Route path="/holdbacks" element={<Holdbacks />} />
            </>
          )}
          {isPricingHistoryEnabled && <Route path="/history" element={<PricingHistory />} />}
        </Routes>
      </MainContainer>
    </>
  );
}
