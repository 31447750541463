import { Box, Card } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { useGetApplicationsQuery } from '../../services/applications';
import { StatusIcon } from '../StatusIcon';
import { CreditApplications } from './CreditApplications';
import { IdentityVerification } from './IdentityVerification';
import { TitleVerification } from './TitleVerification';
import { useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import { ApplicationDocumentWithAdditionalApplicantData, FinancingDecision, MilestoneRequirement } from 'types';
import { TermsAndConditions } from './TermsAndConditions';

export function Underwriting() {
  const { id } = useParams<{ id: any }>();
  const { data: account } = useGetAccountQuery(id);
  const { data: applications = [] } = useGetApplicationsQuery({ accountId: id });

  const statuses = useMemo(
    () =>
      account?.milestones.reduce(
        (acc: any, milestone: any) => {
          const underwritingReq = milestone.requirements.find((req: any) => req.type === 'underwriting');
          if (underwritingReq) {
            acc.underwriting = underwritingReq.status;
            const titleReq = underwritingReq.requirements.find((req: any) => req.type === MilestoneRequirement.titleApproved);
            const creditReq = underwritingReq.requirements.find((req: any) => req.type === MilestoneRequirement.creditApproved);
            const identityReq = underwritingReq.requirements.find((req: any) => req.type === MilestoneRequirement.identityVerified);
            const termsAndConditionsReq = underwritingReq.requirements.find((req: any) => req.type === MilestoneRequirement.acceptTermsAndConditions);
            acc.title = titleReq?.status;
            acc.credit = creditReq?.status;
            acc.identity = identityReq.status;
            acc.termsAndConditions = termsAndConditionsReq?.status;
          }
          return acc;
        },
        {} as Record<any, any>,
      ),
    [account],
  );

  const approvedApplications = applications.filter(
    (application: ApplicationDocumentWithAdditionalApplicantData) =>
      application.status === FinancingDecision.approved ||
      application.status === FinancingDecision.approvedWithStipulations,
  );

  const firstApprovedApplication = approvedApplications[0];

  return (
    <Box>
      <Card>
        <Card.Section>
          <Box alignItems="center" direction="row">
            <Box padding="0 xs 0 0">
              <StatusIcon status={statuses?.underwriting} size="md" />
            </Box>
            <Box as="h3" fontWeight="medium" fontSize="md">
              Underwriting
            </Box>
          </Box>
        </Card.Section>
        {statuses?.credit && <CreditApplications
          status={statuses?.credit}
          applications={applications}
          firstApprovedApplication={firstApprovedApplication}
        />}
        {statuses?.termsAndConditions && <TermsAndConditions status={statuses?.termsAndConditions} />}
        <IdentityVerification status={statuses?.identity} creditStatus={statuses?.credit} programType={account?.programType} />
        {statuses?.title && <TitleVerification
          status={statuses?.title}
          application={firstApprovedApplication}
          creditStatus={statuses?.credit}
        />}
      </Card>
    </Box>
  );
}
