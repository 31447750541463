import { Box, CheckboxInput } from '@palmetto/palmetto-components';
import { ParentReason, activationPackageRejectionReasons, activationPackageSubRejectionReasons } from 'types';
interface RejectionReasonInputsProps {
  handleChange: (field: string, value: any) => any;
  isSubmitting: boolean;
  parentReasons: ParentReason[];
  activationPackageRejection: typeof activationPackageRejectionReasons[];
  activationPackageSubRejection?: typeof activationPackageRejectionReasons[];
}

export const ActivationQueueRejectionReasonCheckboxGroup = ({
  handleChange,
  isSubmitting,
  parentReasons,
  activationPackageRejection,
  activationPackageSubRejection
}: RejectionReasonInputsProps) => {

  const activationPackageParentReason = Object.values(ParentReason);

  const handleParentReasonSelection = ({
    isSelected,
    value,
    selectedOptions,
  }: {
    isSelected: boolean;
    value: ParentReason;
    selectedOptions: ParentReason[];
  }) => {
    const newSelectedOptions = [...selectedOptions];

    if (isSelected) {
      newSelectedOptions.push(value);
    } else {
      const index = selectedOptions.findIndex((selectedOption: any) => selectedOption === value);
      if (index > -1) {
        newSelectedOptions.splice(index, 1);
        handleChange('activationPackageRejection', activationPackageRejection.filter((rejection: any) => rejection.parentReason !== value));
        handleChange('activationPackageSubRejection', activationPackageSubRejection ? activationPackageSubRejection.filter((rejection: any) => rejection.parentReason !== value) : []);
      };
    }
    handleChange('parentReason', newSelectedOptions);
  };

  const handleRejectionOptionSelection = async ({
    isSelected,
    value,
    selectedOptions,
    parentReason
  }: {
    isSelected: boolean;
    value: typeof activationPackageSubRejectionReasons;
    selectedOptions: any;
    parentReason: ParentReason
  }) => {
    const newSelectedOptions = [...selectedOptions];

    if (isSelected) {
      newSelectedOptions.push({ parentReason, rejectedReason: value });
    } else {
      const index = selectedOptions.findIndex((selectedOption: any) => selectedOption.parentReason === parentReason &&
        selectedOption.rejectedReason === value);
      if (index > -1) {
        newSelectedOptions.splice(index, 1)
        const releasedSubRejection = activationPackageSubRejection?.filter((rejection: any) => rejection.parentReason !== parentReason ||
          rejection.rejectedReason !== value);
        handleChange('activationPackageSubRejection', releasedSubRejection);
      };
    }
    await handleChange('activationPackageRejection', newSelectedOptions);
  };

  const handleRejectionSubOptionSelection = async ({
    isSelected,
    value,
    parentReason,
    selectedSubOptions,
    option
  }: {
    isSelected: boolean;
    value: typeof activationPackageRejectionReasons;
    parentReason: ParentReason
    selectedSubOptions: any;
    option: any;
  }) => {
    const newSelectedSubOptions = [...selectedSubOptions];

    if (isSelected) {
      newSelectedSubOptions.push({ parentReason, rejectedReason: option, subRejectedReason: value });
    } else {
      const index = selectedSubOptions.findIndex((selectedSubOption: any) => selectedSubOption.parentReason === parentReason && selectedSubOption.rejectedReason === option && selectedSubOption.subRejectedReason === value);
      if (index > -1) newSelectedSubOptions.splice(index, 1);
    }

    await handleChange('activationPackageSubRejection', newSelectedSubOptions);
  };

  return (
    <Box gap="sm">
      {activationPackageParentReason.map((parentReason) => (
        <Box key={parentReason} gap="sm">
          <CheckboxInput
            id={parentReason}
            label={parentReason}
            name={parentReason}
            isChecked={parentReasons?.includes(parentReason) || false}
            isDisabled={isSubmitting}
            onChange={(event) =>
              handleParentReasonSelection({
                isSelected: event.target.checked,
                value: parentReason,
                selectedOptions: parentReasons,
              })
            }
          />
          {parentReasons.includes(parentReason) &&
            activationPackageRejectionReasons[parentReason] && (
              <Box gap="sm" margin="0 0 0 lg">
                {Object.values(activationPackageRejectionReasons[parentReason]).map(
                  (option) => (
                    <Box key={`${parentReason}-${option}`} gap="sm">
                      <CheckboxInput
                        id={`${parentReason}-${option}`}
                        label={option}
                        name={option}
                        isChecked={
                          activationPackageRejection.some(
                            (selectedOption: any) =>
                              selectedOption.parentReason === parentReason &&
                              selectedOption.rejectedReason === option
                          ) || false
                        }
                        isDisabled={isSubmitting}
                        onChange={(event) =>
                          handleRejectionOptionSelection({
                            isSelected: event.target.checked,
                            value: option,
                            selectedOptions: activationPackageRejection,
                            parentReason: parentReason,
                          })
                        }
                      />
                      {activationPackageRejection.some(
                        (selectedOption: any) =>
                          selectedOption.parentReason === parentReason &&
                          selectedOption.rejectedReason === option
                      ) && (
                          <Box gap="sm" margin="0 0 0 lg">
                            {activationPackageSubRejectionReasons[option as keyof typeof activationPackageSubRejectionReasons] && Object.values(
                              activationPackageSubRejectionReasons[option as keyof typeof activationPackageSubRejectionReasons]
                            ).map((subOption, subIndex) => (
                              <CheckboxInput
                                id={`${option}-${subOption}`}
                                label={subOption}
                                name={subOption}
                                key={`${option}-${subIndex}`}
                                isChecked={
                                  activationPackageSubRejection?.some(
                                    (selectedOption: any) =>
                                      selectedOption.parentReason === parentReason &&
                                      selectedOption.rejectedReason === option &&
                                      selectedOption.subRejectedReason == subOption
                                  ) || false
                                }
                                isDisabled={isSubmitting}
                                onChange={(event) =>
                                  handleRejectionSubOptionSelection({
                                    isSelected: event.target.checked,
                                    parentReason: parentReason,
                                    value: subOption,
                                    selectedSubOptions: activationPackageSubRejection,
                                    option: option,
                                  })
                                }
                              />
                            ))}
                          </Box>
                        )}
                    </Box>
                  )
                )}
              </Box>
            )}
        </Box>
      ))}
    </Box>
  );
};
