import { Box, Button, FormikTextareaInput, Modal, RadioGroup, toast } from '@palmetto/palmetto-components';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useCreateAccountStipulationMutation } from '../../services/accounts';
import { AccountStipulationReason, AccountStipulationType } from 'types';

interface ApplyAdminStipulationProps {
  accountId: string;
  handleClose: () => void;
  isOpen: boolean;
}

const accountSchema = yup.object().shape({
  description: yup.string().required('Please provide an explanation for this stipulation'),
});

export const ApplyAdminStipulationModal = ({ accountId, handleClose, isOpen }: ApplyAdminStipulationProps) => {
  const [createAccountStipulation] = useCreateAccountStipulationMutation();

  const handleSubmit = async (
    values: {
      description: string;
      reason: AccountStipulationReason;
    },
    { setSubmitting }: any,
  ) => {
    const { description, reason } = values || {};
    try {
      const stipulation = {
        description: description === '' ? undefined : description,
        reason,
        stipulationType: AccountStipulationType.administrative,
      };
      await createAccountStipulation({
        stipulation,
        id: accountId,
      }).unwrap();
      await toast.success('Administration stipulation was applied.');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error applying the stipulation.');
    }
    setSubmitting(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => {
        handleClose();
      }}
      maxWidth="4xl"
      ariaLabelledBy="adminStipulationHeader"
      fullScreenMobile
      overflow="scroll"
    >
      <Modal.Header
        id="adminStipulationHeader"
        title="Apply Administrative Stipulation"
        onDismiss={() => {
          handleClose();
        }}
      />
      <Formik
        initialValues={{ description: '', reason: AccountStipulationReason.other }}
        validationSchema={accountSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form noValidate id="cancelReactivateForm">
            <Modal.Body background="secondary" gap="md" fontSize="sm">
              <Box
                background="warning"
                gap="md"
                style={{ padding: '1.5rem', margin: '-1.5rem -1.5rem 0.75rem -1.5rem' }}
              >
                Applying an administrative stipulation will:
                <Box as="ul" gap="xs">
                  <li>Prevent the next milestone from being approved</li>
                  {/*
                    // TODO: Enable when system is ready to send these types of emails...
                    <li>Send an email to the sales rep and organization's milestone contact email address</li>
                  */}
                </Box>
              </Box>
              <Box as="p" fontWeight="bold" color="body-primary">
                Why are you applying a stipulation?
              </Box>
              <RadioGroup
                name="reason"
                id="reason"
                value={values.reason}
                onChange={(event) => {
                  setFieldValue('reason', event.target.value as AccountStipulationReason);
                }}
                options={[
                  {
                    label: 'Housing Stock',
                    value: AccountStipulationReason.housingStock,
                    id: AccountStipulationReason.housingStock,
                  },
                  {
                    label: 'Account Details',
                    value: AccountStipulationReason.accountDetails,
                    id: AccountStipulationReason.accountDetails,
                  },
                  {
                    label: 'Consumer Compliance Details',
                    value: AccountStipulationReason.consumerCompliance,
                    id: AccountStipulationReason.consumerCompliance,
                  },
                  {
                    label: 'Customer Action Required',
                    value: AccountStipulationReason.ageRelated,
                    id: AccountStipulationReason.ageRelated,
                  },
                  {
                    label: 'Other',
                    value: AccountStipulationReason.other,
                    id: AccountStipulationReason.other,
                  },
                ]}
              />
              <Box gap="md">
                <Field
                  name="description"
                  id="description"
                  component={FormikTextareaInput}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                  placeholder="Write an explanation to be shared with EPC here..."
                />
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
                Cancel
              </Button>
              <Button size="md" tone="danger" type="submit" isLoading={isSubmitting} iconPrefix="flag">
                Apply Stipulation
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
