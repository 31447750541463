import { api } from './api';

export const termsAndConditionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    acceptTermsAndConditions: build.mutation<
      void,
      {
        accountId: string;
        inviteToken: string | null;
      }
    >({
      query: ({ accountId, inviteToken }) => ({
        url: `accounts/${accountId}/terms-and-conditions/accept`,
        method: 'POST',
        params: {
          ...(inviteToken ? { inviteToken } : {}),
        },
      }),
    }),
  }),
});

export const { useAcceptTermsAndConditionsMutation } = termsAndConditionsApi;
