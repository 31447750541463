import { Box, Button, FormLabel, FormikRadioGroup, FormikTextareaInput, Modal } from '@palmetto/palmetto-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { DocumentRejectionReasons } from 'types';

interface ReinstateContractModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => Promise<void>;
}

export const ReinstateContractModal = ({
  isModalOpen,
  handleClose,
  handleSubmit,
}: ReinstateContractModalProps) => (
  <Modal
    isOpen={isModalOpen}
    onDismiss={handleClose}
    maxWidth="4xl"
    ariaLabelledBy="ReinstateContractModalHeader"
    fullScreenMobile
  >
    <Modal.Header
      id="ReinstateContractModalHeader"
      title="Reinstate Contract"
      onDismiss={handleClose}
    />
    <Formik
      initialValues={{ rejectionReason: '' as any, notes: '' }}
      onSubmit={handleSubmit}
      enableReinitialize={false}
    >
      {({ isSubmitting }) => (
        <Form noValidate id="ReinstateContractModal">
          <Modal.Body background="secondary" fontSize="md">
            <Box gap="md">
              <p>Reinstating a voided contract will:</p>
              <ul>
                <li style={{ paddingTop: '5px' }}>Reinstate the quote associated with this contract</li>
                <li style={{ paddingTop: '5px' }}>Reinstate the signed DocuSign contract</li>
              </ul>
              <p>
                Note: If a voided contract has not been signed, it cannot be reinstated.
              </p>
            </Box>
          </Modal.Body>
          <Modal.Footer justifyContent={'flex-end'}>
            <Box direction="row" gap="sm">
              <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
                Cancel
              </Button>
              <Button tone="primary" size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                Reinstate Contract
              </Button>
            </Box>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  </Modal>
);
