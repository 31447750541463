import { ObjectId } from 'mongodb';
import { ProgramType } from './ProgramTypes';

export enum FinanceType {
  lease = 'lease',
  ppa = 'ppa',
}

export enum ProductStatus {
  active = 'active',
  archived = 'archived',
  deprecated = 'deprecated',
}

export interface PpaPricePerWattOption {
  productionFactor: number;
  ppw: number;
}

export interface BaseProduct {
  id: ObjectId;
  priceSheetId: string | ObjectId;
  organizationId?: string;
  programType: ProgramType;
  name: string;
  description?: string;
  status: ProductStatus;
  deprecationDate?: Date;
  escalationRate: number;
}

export interface SolarProductDocument extends BaseProduct {
  inboundPaymentProviderProductId?: string;
  type?: FinanceType;
  lseId?: number;
  utilityName?: string;
  state?: string;
  kwhRate: number;
  ppwRateOptions: Array<PpaPricePerWattOption>;
}

export interface DoePrProductDocument extends BaseProduct {
  ppwRate: number;
  monthlyPayment: number;
  minSystemSize: number;
  maxSystemSize: number;
  minBatteryCapacity: number;
  maxBatteryCapacity: number;
}

export interface HVACProductDocument extends BaseProduct {
  term: number;
  paymentFactor: number;
  minSystemCost: number;
  maxSystemCost: number;
}

export type ProductDocument<T extends ProgramType> = T extends 'solar'
  ? SolarProductDocument
  : T extends 'doePr'
    ? DoePrProductDocument
    : T extends 'hvac'
      ? HVACProductDocument
      : SolarProductDocument;

export interface PaymentSchedule {
  year: number;
  monthlyPayment: number;
  backupBatteryPayment?: number; //TODO:  make these required once pricing changes are done.
  totalMonthlyPayment?: number;
}
export interface ProductPricingResponse {
  productId: ObjectId;
  type?: string;
  name: string;
  description?: string;
  lseId?: number;
  utilityName?: string;
  state?: string;
  escalationRate: number;
  kwhRate: number;
  ppwRate: number;
  solarOnlyEPCPayment: number;
  totalEPCPayment: number;
  backupBatteryCost?: number;
  monthlyPayments: Array<PaymentSchedule>;
  programType: ProgramType;
}

export interface HVACProductPricingResponse {
  productId: ObjectId;
  type?: string;
  name: string;
  description?: string;
  lseId?: number;
  utilityName?: string;
  state?: string;
  escalationRate: number;
  monthlyPayments: Array<PaymentSchedule>;
  programType: ProgramType;
}
