import { ApprovedVendorSettings, ProgramType, VendorEquipmentTypes } from 'types';
import { api } from './api';

const tag = 'ApprovedVendors';
export const approvedVendorsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApprovedVendors: build.query<ApprovedVendorSettings, { programType: ProgramType }>({
      query: ({ programType }) => ({ url: `approved-vendors${programType ? '?programType=' + programType : ''}` }),
      providesTags: (_result, _error, { programType }) => [{ type: tag, programType, equipmentType: 'all' }],
    }),
    getApprovedVendorsByType: build.query<any, { programType: ProgramType; equipmentType?: VendorEquipmentTypes }>({
      query: ({ programType, equipmentType }) => ({
        url: `approved-vendors/${equipmentType}${programType ? '?programType=' + programType : ''}`,
      }),
      providesTags: (_result, _error, { programType, equipmentType }) => [
        { type: tag, programType, equipmentType: equipmentType ?? 'all' },
      ],
    }),
  }),
});

export const { useGetApprovedVendorsQuery, useGetApprovedVendorsByTypeQuery } = approvedVendorsApi;
