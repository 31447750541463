export enum ParentReason {
  inverterMonitoringId = 'Inverter Monitoring ID',
  batteryMonitoringId = 'Battery Monitoring ID (IF there is a Battery Adder, either Backup or Arbitrage)',
  incentives = 'Incentives',
  extendedWarranty = 'Extended Warranty',
  mediumToHighPriority = 'Medium to High Priority',
}

export enum InverterMonitoringIdRejectionOptions {
  systemSettings = 'System Settings',
  ownershipAccess = 'Ownership Access',
  ctIssues = 'CT Issues',
  physicalLayout = 'Physical Layout',
  productionConsumptionData = 'Production/Consumption Data',
  systemEventError = 'System Event/Error',
}

export enum BatteryMonitoringIdRejectionOptions {
  systemSettings = 'System Settings',
  ownershipAccess = 'Ownership Access',
  ctIssues = 'CT Issues',
  productionConsumptionData = 'Production/Consumption Data',
  systemEventError = 'System Event/Error',
}

export enum IncentivesRejectionOptions {
  notComplete = 'Not Complete',
  missingDocumentation = 'Missing Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  illegibleDocumentation = 'Illegible Documentation',
}

export enum ExtendedWarrantyRejectionOptions {
  notComplete = 'Not Complete',
  missingDocumentation = 'Missing Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  illegibleDocumentation = 'Illegible Documentation',
}

export enum SiteImprovementsRejectionOptions {
  mOneApprovalStip = 'M1 Approval Stip - Missing/Incorrect',
  CommControlCable = 'Comm/Control Cable - Missing/Incorrect',
  inlineFilter = 'Inline Filter - Missing/Incorrect',
  asBuilt = 'As Built - Missing/Incorrect',
  electricalRedFlag = 'Electrical Red Flag',
  missingDocumentation = 'Missing Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  illegibleDocumentation = 'Illegible Documentation',
}

export enum MediumToHighPriorityRejectionOptions {
  reducesCircleTimesWithCommonIssues = 'Reduces Circle Times with Common Issues',
  mTwoFirstTouchApprovalStip = 'M2 First Touch Approval',
  reduceReviewDuration = 'Reduce Review Duration',
  auditDiligenceCoverage = 'Audit Diligence Coverage',
}

export enum SystemSettingsRejectionSubOptions {
  pcs = 'PCS Missing/Incorrect',
  systemDetails = 'System Details Incorrect',
  arbitrageSettings = 'Arbitrage Settings Missing/Incorrect',
  backupSettings = 'Backup Settings Missing/Incorrect',
  cellularModem = 'Cellular Modem Incorrect',
}

export enum OwnershipAccessRejectionSubOptions {
  missingThirdPartyAssignment = 'Missing 3rd Party Assignment',
  ownershipToLightReach = 'Ownership to LightReach - Incomplete',
  noAccessOrIncorrectSiteId = 'No Access/Incorrect Site ID',
}

export enum CtIssuesRejectionSubOptions {
  productionConsumptionCts = 'Production/Consumption CTs Missing/Disabled',
  polarityIncorrect = 'Polarity Incorrect',
  locationConfiguration = 'Location/Configuration Incorrect',
  terminationsSplices = 'Terminations/Splices Missing/Incorrect',
  lOneLTwoPhases = 'L1/L2 Phases Incorrect',
  secondSystem = 'Second System - Missing/Incorrect',
  additionalCts = 'Additional CTs Missing/Incorrect',
}

export enum PhysicalLayoutRejectionSubOptions {
  layoutMissMatch = 'Layout Mismatch',
  moduleAssignment = 'Module Assignment - Missing/Incorrect',
  tiltAzimuth = 'Tilt/Azimuth - Incorrect',
}

export enum ProductionConsumptionDataRejectionSubOptions {
  productionData = 'Production Data - Missing/Incorrect',
  consumptionData = 'Consumption Data - Missing/Correlating/Incorrect',
  consumptionDataNegative = 'Consumption Data - Negative',
}

export enum SystemEventErrorRejectionSubOptions {
  microInverterGatewayMeterNotCommunicating = 'Micro Inverter/Gateway/Meter Not Communicating',
  pcsProductionErrorPowerGenerationOffByCommand = 'PCS Production Error/Power Generation Off by Command',
  miscellaneous = 'Miscellaneous',
}

export const activationPackageRejectionReasons = {
  [ParentReason.inverterMonitoringId]: InverterMonitoringIdRejectionOptions,
  [ParentReason.batteryMonitoringId]: BatteryMonitoringIdRejectionOptions,
  [ParentReason.incentives]: IncentivesRejectionOptions,
  [ParentReason.extendedWarranty]: ExtendedWarrantyRejectionOptions,
  [ParentReason.mediumToHighPriority]: MediumToHighPriorityRejectionOptions,
};

export const activationPackageSubRejectionReasons = {
  [InverterMonitoringIdRejectionOptions.systemSettings]: SystemSettingsRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.ownershipAccess]: OwnershipAccessRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.ctIssues]: CtIssuesRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.physicalLayout]: PhysicalLayoutRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.productionConsumptionData]: ProductionConsumptionDataRejectionSubOptions,
  [InverterMonitoringIdRejectionOptions.systemEventError]: SystemEventErrorRejectionSubOptions,
};

export type PackageRejectionInfo = {
  option: ParentReason;
  rejectionReasons: [
    {
      reason: (typeof activationPackageRejectionReasons)[];
      subReasons: (typeof activationPackageSubRejectionReasons)[];
    },
  ];
};
