import { Box, useBreakpoint } from '@palmetto/palmetto-components';
import { OrganizationNode } from '@/types/Organizations';
import { ProgramType } from 'types';
import PaymentCard from '../components/PaymentCard';
import PaymentPlanTable from './PaymentPlanTable';
import { useGetOrganizationPaymentScheduleQuery } from '@/services/organizations';

interface PaymentPlanProps {
  organization: OrganizationNode;
}

export const PaymentPlan = ({ organization }: PaymentPlanProps) => {
  const { data: paymentPlan, isLoading: isLoadingPaymentPlan } =
    useGetOrganizationPaymentScheduleQuery(organization);

  const renderPaymentPlan = (content: React.ReactNode) => (
    <PaymentCard
      isLoading={isLoadingPaymentPlan}
      organization={organization}
      cardTitle="Payment Schedule"
      href={`/settings/organizations/${organization?.alias}/payments/edit`}
    >
      {content}
    </PaymentCard>
  );

  if (!paymentPlan) {
    return renderPaymentPlan(
      <Box alignItems="center" justifyContent="space-evenly" height="300px">
        <Box>No Payment Schedule</Box>
      </Box>,
    );
  }

  return renderPaymentPlan(
    <PaymentPlanTable
      paymentPlan={paymentPlan}
      inherited={paymentPlan.inherited}
      scheduleOwnerAlias={String(paymentPlan.scheduleOwnerAlias)}
      programType={organization?.programTypes?.[0] ?? ProgramType.solar}
      selectable={false}
    />,
  );
};
