import { HistorySearchQueryPayload } from 'types';
import { api } from './api';

export const historyAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getHistory: build.query<any, HistorySearchQueryPayload | undefined>({
      query: (arg) => {
        return {
          url: `/history${
            arg
              ? (() => {
                  const searchParams = new URLSearchParams();
                  if (arg.types) {
                    searchParams.append('types', JSON.stringify(arg.types));
                  }
                  if (arg.pageNum && !isNaN(arg.pageNum)) {
                    console.log(arg.pageNum);
                    searchParams.append('pageNum', `${arg.pageNum}`);
                  }
                  if (arg.sort) {
                    searchParams.append('sort', arg.sort);
                  }
                  if (arg.pageSize && !isNaN(arg.pageSize)) {
                    searchParams.append('pageSize', `${arg.pageSize}`);
                  }
                  return searchParams.size > 0 ? `?${searchParams.toString()}` : '';
                })()
              : ''
          }`,
        };
      },
      providesTags: () => [{ type: 'History', id: 'LIST' }],
    }),
  }),
});

export const { useGetHistoryQuery } = historyAPI;
