import { Readable } from 'stream';
import { ObjectId } from 'mongodb';
import {
  CreatePriceSheetMapping,
  Meta,
  PpaPricePerWattOption,
  PriceSheetMapping,
  PriceSheetMappingBulkChangesPayload,
  ProgramType,
} from '.';

export enum PriceSheetStatus {
  active = 'active',
  archived = 'archived',
}

export interface BasePriceSheet {
  id: ObjectId;
  name: string;
  description?: string;
  status: PriceSheetStatus;
  escalationRates: Array<number>;
  createdDate: Date;
  archivedDate?: Date;
  meta?: Meta;
  programType: ProgramType;
}

export interface SolarPriceSheetDocument extends BasePriceSheet {
  kwhRateFloor: number;
  kwhRateCeiling: number;
  productionFactorFloor: number;
  productionFactorCeiling: number;
}

export interface DoePrPriceSheetDocument extends BasePriceSheet {
  minSystemSize: number;
  maxSystemSize: number;
  minBatteryCapacity: number;
  maxBatteryCapacity: number;
}

export interface HVACPriceSheetDocument extends BasePriceSheet {
  terms: Array<number>;
  systemCosts: Array<number>;
}

// export type PriceSheetDocument = SolarPriceSheetDocument | DoePrPriceSheetDocument;
export type PriceSheetDocument<T extends ProgramType> = T extends 'solar'
  ? SolarPriceSheetDocument
  : T extends 'doePr'
    ? DoePrPriceSheetDocument
    : T extends 'hvac'
      ? HVACPriceSheetDocument
      : SolarPriceSheetDocument;

export interface SolarPriceEntry {
  escalationRate: number;
  kwhRate: number;
  ppwRateOptions: Array<PpaPricePerWattOption>;
}

export interface DoePrPricePerWattOption {
  ppwRate: number;
  monthlyPayment: number;
  minSystemSize: number;
  maxSystemSize: number;
  minBatteryCapacity: number;
  maxBatteryCapacity: number;
}

export interface DoePrPriceEntry extends DoePrPricePerWattOption {
  escalationRate: number;
}

export interface HVACPriceEntry {
  escalationRate: number;
  term: number;
  paymentFactor: number;
  minSystemCost: number;
  maxSystemCost: number;
}

export interface PriceSheetDetails<T extends ProgramType> {
  id: ObjectId;
  name: string;
  prices: Array<
    T extends 'solar'
      ? SolarPriceEntry
      : T extends 'doePr'
        ? DoePrPriceEntry
        : T extends 'hvac'
          ? HVACPriceEntry
          : SolarPriceEntry
  >;
}

export interface PriceSheetService<T extends ProgramType> {
  programType: ProgramType;
  applyBulkPriceSheetMappings(
    data: PriceSheetMappingBulkChangesPayload,
  ): Promise<PriceSheetMapping<T>[] | undefined>;
  createPriceSheetMapping(data: CreatePriceSheetMapping<T>): Promise<PriceSheetMapping<T>[]>;
  importPriceSheet(priceSheetName: string, priceSheetContents: Readable): Promise<PriceSheetDocument<T>>;
}
