import { Link, NavLink, Outlet, ScrollRestoration } from 'react-router-dom';
import {
  Box,
  Button,
  Drawer,
  ToastContainer,
  useBreakpoint,
  useOpenClose,
  useTheme,
} from '@palmetto/palmetto-components';
import '@palmetto/palmetto-components/dist/css/reset.css';
import '@palmetto/palmetto-components/dist/css/utilities.css';
import '@palmetto/palmetto-components/dist/css/variables.css';
import '@palmetto/palmetto-components/dist/css/index.css';
import '@palmetto/palmetto-components/dist/css/fonts.css';
import './App.css';
import { UserMenu } from './components/UserMenu';
import OrganizationMenu from './components/OrganizationMenu';
import RequirePalmettoFinanceUser from './components/auth/RequirePalmettoFinanceUser';
import Nav from './components/settings/nav';
import RequirePricingAdminUser from './components/auth/RequirePricingAdminUser';
import { DropdownMenu } from './components/DropdownMenu';
import { useAuth } from './components/auth/authProvider';
import { useIntercom } from 'react-use-intercom';
import { useInviteToken } from './components/auth/useInviteToken';
import { useFlags } from 'launchdarkly-react-client-sdk';

const adminMenuItems = [
  { name: 'Markets', path: '/admin/markets' },
  { name: 'Pricing', path: '/admin/pricing/solar' },
  { name: 'Funds', path: '/admin/funds' },
];
const accountMenuItems = [
  { name: 'Pipeline', path: '/accounts' },
  { name: 'Post-Activation', path: '/accounts?postActivation' },
  { name: 'Cancelled', path: '/accounts?cancelled', permissions: ['admin'] },
];

function App() {
  useIntercom();
  const { isPhone } = useBreakpoint();
  const { isAuthenticated } = useAuth();
  const { isInviteToken } = useInviteToken();
  const { ptoQueue = false } = useFlags();

  const { isOpen: isDrawerOpen, handleClose: closeDrawer, handleToggle: toggleDrawer } = useOpenClose();

  const { theme } = useTheme();

  let logoUrl = theme === 'light' ? '/palmetto-brand-logo.svg' : '/palmetto-brand-logo-dark.svg';

  if (theme === 'system') {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

    logoUrl = systemTheme === 'light' ? '/palmetto-brand-logo.svg' : '/palmetto-brand-logo-dark.svg';
  }

  const queueItems = [
    { name: 'Review Identity Upload', path: '/queue/review-identity' },
    { name: 'Review Title Upload', path: '/queue/review-title' },
    {
      name: 'Review Voided Check Upload',
      path: '/queue/voided-check',
    },
    { name: 'Review Supporting Upload', path: '/queue/review-supporting' },
    { name: 'Review Disclosure Upload', path: '/queue/review-disclosure' },
    {
      name: 'Review Install Package',
      path: '/queue/install-package',
    },
    ...(ptoQueue
      ? [
          {
            name: 'Review PTO Package',
            path: '/queue/pto-package',
          },
        ]
      : []),
    {
      name: 'Review Activation Package',
      path: '/queue/activation-package',
    },
    {
      name: 'Review Electrician Sign-off',
      path: '/queue/electrician-sign-off',
    },
  ];

  return (
    <Box background="secondary" color="body-primary" fontSize="sm">
      <ScrollRestoration />
      <ToastContainer />
      {isPhone && !isInviteToken && (
        <Drawer isOpen={isDrawerOpen} onDismiss={closeDrawer} ariaLabel="menu" placement="left">
          <Box
            padding="4xl sm lg sm"
            fontSize="sm"
            background="secondary"
            height="100"
            childGap="sm"
            className="settings-nav"
          >
            <Box margin="lg 0 0 0" padding="0 0 0 sm" fontWeight="medium" color="contrast">
              Accounts
            </Box>

            <Box>
              {accountMenuItems?.map((item: any) => (
                <NavLink key={item.path} to={item.path} onClick={closeDrawer}>
                  {item.name}
                </NavLink>
              ))}
            </Box>

            <RequirePalmettoFinanceUser>
              <Box margin="lg 0 0 0" padding="0 0 0 sm" fontWeight="medium" color="contrast">
                Queues
              </Box>

              <Box>
                {queueItems?.map((item: any) => (
                  <NavLink key={item.path} to={item.path} onClick={closeDrawer}>
                    {item.name}
                  </NavLink>
                ))}
              </Box>
            </RequirePalmettoFinanceUser>
            <RequirePricingAdminUser>
              <Box margin="lg 0 0 0" padding="0 0 0 sm" fontWeight="medium" color="contrast">
                Admin
              </Box>

              <Box>
                {adminMenuItems?.map((item: any) => (
                  <NavLink key={item.path} to={item.path} onClick={closeDrawer}>
                    {item.name}
                  </NavLink>
                ))}
              </Box>
            </RequirePricingAdminUser>

            <Box margin="lg 0 0 0" padding="0 0 0 sm" fontWeight="medium" color="contrast">
              Settings
            </Box>
            <Nav onNavLinkClick={closeDrawer} />
          </Box>
        </Drawer>
      )}
      <Box
        as="header"
        borderWidth="0 0 xs 0"
        borderColor="separator"
        direction="row"
        justifyContent="space-between"
        fontSize={{ base: 'sm', desktop: 'md' }}
        background="primary"
      >
        <Box
          direction="row"
          childGap="md"
          alignItems="center"
          fontSize="sm"
          justifyContent="center"
          padding={{ base: 'sm md', desktop: 'md lg' }}
        >
          {isPhone && !isInviteToken ? (
            <Button
              iconPrefix="menu"
              size="sm"
              variant="tertiary"
              tone="neutral"
              onClick={toggleDrawer}
              style={{ zIndex: isDrawerOpen ? 'var(--size-z-index-popover)' : undefined }}
            />
          ) : !isInviteToken ? (
            <Link to="/">
              <img src={logoUrl} alt="Palmetto" height={isPhone ? '24px' : '32px'} />
            </Link>
          ) : (
            <img src="/palmetto-brand-logo.svg" alt="Palmetto" height={isPhone ? '24px' : '32px'} />
          )}
          {isAuthenticated && <OrganizationMenu />}
          {!isPhone && !isInviteToken && (
            <>
              <DropdownMenu items={accountMenuItems} title="Accounts" />
              <RequirePalmettoFinanceUser>
                <DropdownMenu items={queueItems} title="Queues" />
              </RequirePalmettoFinanceUser>
              <RequirePalmettoFinanceUser>
                <DropdownMenu items={adminMenuItems} title="Admin" />
              </RequirePalmettoFinanceUser>
            </>
          )}
        </Box>
        {isAuthenticated && <UserMenu />}
      </Box>
      <Box
        margin={{ base: 'lg auto 5xl', desktop: '2xl auto 5xl' }}
        padding={{ tablet: '0 lg', desktop: '0 3xl', hd: '0 4xl' }}
        width="100"
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default App;
