import { useEffect } from 'react';
import { Field, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { Box, Button, FormikRadioGroup, FormikTextInput } from '@palmetto/palmetto-components';
import { object, string, Schema } from 'yup';
import { PaymentPlan as PaymentPlanType, PaymentSettingsWithMetadata, ProgramType } from 'types';
import PaymentPlanTable from './PaymentPlanTable';

export interface PaymentFormEditData {
  shouldHavePaymentSchedule: string;
  paymentScheduleId?: string | undefined;
  legalEntity: string;
  setUpBankAccount: string;
  netSuiteVendorId?: string | undefined;
}

const paymentSchema = {
  shouldHavePaymentSchedule: string().required().oneOf(['yes', 'no']),
  paymentScheduleId: string().when('shouldHavePaymentSchedule', {
    is: 'yes',
    then: (schema) => schema.required('A payment schedule is required'),
    otherwise: (schema) => schema.optional(),
  }),
  legalEntity: string().required().oneOf(['yes', 'no']),
  setUpBankAccount: string().required().oneOf(['yes', 'no']),
  netSuiteVendorId: string().optional(),
};

export const PaymentFormSchema: Schema<PaymentFormEditData> = object().shape(paymentSchema).noUnknown(true);

type SubmitHandler = (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;

interface PaymentFormProps {
  initialValues: any;
  handleSubmit: SubmitHandler;
  isLoading: boolean;
  disabledFields?: string[];
  organizationName?: string;
  programType: ProgramType;
  paymentPlan: (PaymentPlanType & PaymentSettingsWithMetadata) | undefined;
}

export const PaymentForm = ({
  initialValues,
  handleSubmit,
  isLoading,
  organizationName,
  programType,
  paymentPlan,
}: PaymentFormProps) => {
  const shouldHavePaymentScheduleOptions = [
    {
      id: 'shouldHavePaymentScheduleTrue',
      value: 'yes',
      label: 'Yes',
    },
    {
      id: 'shouldHavePaymentScheduleFalse',
      value: 'no',
      label: 'No, payments schedule should be inherited from parent organization.',
    },
  ];

  const legalEntityOptions = [
    {
      id: 'legalEntityTrue',
      value: 'yes',
      label: 'Yes',
    },
    {
      id: 'legalEntityFalse',
      value: 'no',
      label: 'No',
    },
  ];

  const setUpBankAccountOptions = [
    {
      id: 'setUpBankAccountTrue',
      value: 'yes',
      label: `Yes`,
    },
    {
      id: 'setUpBankAccountFalse',
      value: 'no',
      label: 'No, payments should be made to parent organization',
    },
  ];

  const InheritScheduleInput = (props: any) => {
    const { setFieldValue, values }: any = useFormikContext();
    useEffect(() => {
      if (values.shouldHavePaymentSchedule === 'no') {
        setFieldValue('paymentScheduleId', undefined);
      }
    }, [setFieldValue, values.shouldHavePaymentSchedule]);

    return (
      <Field
        title={`Should ${organizationName} have its own payment schedule?`}
        name="shouldHavePaymentSchedule"
        id="shouldHavePaymentSchedule"
        options={shouldHavePaymentScheduleOptions}
        component={FormikRadioGroup}
        isRequired
        isDisabled={props.isSubmitting}
      />
    );
  };

  const IsLegalEntityInput = (props: any) => {
    const { setFieldValue, values }: any = useFormikContext();
    useEffect(() => {
      if (values.legalEntity === 'no') {
        setFieldValue('netSuiteVendorId', undefined);
      }
    }, [setFieldValue, values.legalEntity, values.shouldHavePaymentSchedule]);

    return (
      <Field
        title={`Does ${organizationName} represent its own legal entity?`}
        name="legalEntity"
        id="legalEntity"
        options={legalEntityOptions}
        component={FormikRadioGroup}
        isRequired
        isDisabled={props.isSubmitting}
      />
    );
  };

  return (
    <>
      <Formik
        validationSchema={PaymentFormSchema}
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        enableReinitialize={false}
      >
        {({ isSubmitting, values, errors }) => (
          <Form noValidate>
            <Box childGap="xl">
              <Box childGap="xl">
                <Box>
                  <InheritScheduleInput isSubmitting={isSubmitting} initialValues={initialValues} />
                </Box>
                {values.shouldHavePaymentSchedule === 'yes' && (
                  <Box>
                    <PaymentPlanTable
                      paymentPlan={paymentPlan}
                      inherited={paymentPlan?.inherited}
                      scheduleOwnerAlias={String(paymentPlan?.scheduleOwnerAlias)}
                      programType={programType}
                      selectable={true}
                    />
                    {errors?.paymentScheduleId && <Box color="danger">{errors.paymentScheduleId as string}</Box>}
                  </Box>
                )}
              </Box>
              <Box>
                <Field
                  title={`Should ${organizationName} set up their own bank account to receive project payments?`}
                  name="setUpBankAccount"
                  id="setUpBankAccount"
                  options={setUpBankAccountOptions}
                  component={FormikRadioGroup}
                  isRequired
                  isDisabled={isSubmitting}
                />
              </Box>
              <Box>
                <Box>
                  <IsLegalEntityInput isSubmitting={isSubmitting} />
                </Box>
                {errors?.legalEntity && <Box color="danger">{errors.legalEntity as string}</Box>}
              </Box>
              {errors?.setUpBankAccount && <Box color="danger">{errors.setUpBankAccount as string}</Box>}
              {values.legalEntity === 'yes' && (
                <Box width="300px">
                  <Field
                    type="text"
                    label="NetSuite Vendor Id"
                    name="netSuiteVendorId"
                    id="netSuiteVendorId"
                    component={FormikTextInput}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                  />
                </Box>
              )}
            </Box>

            <Box
              direction={{
                base: 'column',
                tablet: 'row',
              }}
              alignItems={{
                base: 'stretch',
                tablet: 'flex-end',
              }}
              justifyContent={{
                tablet: 'flex-end',
              }}
              childGap="sm"
              style={{ flexShrink: 0 }}
              padding="lg 0 0 0"
            >
              <Button
                as="a"
                href="/settings/organizations"
                variant="secondary"
                tone="neutral"
                size="md"
                isDisabled={isLoading}
                isLoading={isSubmitting}
              >
                Cancel
              </Button>
              <Button variant="primary" size="md" type="submit" isDisabled={isLoading} isLoading={isSubmitting}>
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
