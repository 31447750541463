import { Box, Button, Modal, TextInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import * as yup from 'yup';
import { AdderType, ProgramType, adderMap } from 'types';
import { useCreateConstructionAdderMutation } from '../../../services/pricing';
import { useParams } from 'react-router-dom';

export const CreateBackupBatteryModal = ({
  isModalOpen,
  closeModal,
  adderType,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
  adderType?: string;
}) => {
  const { programType = ProgramType.solar } = useParams<{ programType: string }>();

  const [createConstructionAdder] = useCreateConstructionAdderMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      zeroEsc: undefined,
      zeroNineNineEsc: undefined,
      oneEsc: undefined,
      oneNineNineEsc: undefined,
      twoNineNineEsc: undefined,
      threeFiveEsc: undefined,
    },
    validationSchema: yup.object({
      name: yup.string().required('Option Name is required'),
      zeroEsc: yup.number().required('0% Escalator is required'),
      zeroNineNineEsc: yup
        .number()
        .test('0.99-required-based-on-program', '0.99% Escalator is required', function (value) {
          return programType === ProgramType.solar ? value !== undefined : true;
        }),
      oneEsc: yup.number().test('1-required-based-on-program', '1% Escalator is required', function (value) {
        return programType === ProgramType.doePr ? value !== undefined : true;
      }),
      oneNineNineEsc: yup
        .number()
        .test('1.99-required-based-on-program', '1.99% Escalator is required', function (value) {
          return programType === ProgramType.solar ? value !== undefined : true;
        }),
      twoNineNineEsc: yup
        .number()
        .test('2.99-required-based-on-program', '2.99% Escalator is required', function (value) {
          return programType === ProgramType.solar ? value !== undefined : true;
        }),
      threeFiveEsc: yup
        .number()
        .test('3.5-required-based-on-program', '3.5% Escalator is required', function (value) {
          return programType === ProgramType.solar ? value !== undefined : true;
        }),
    }),
    onSubmit: async (values: FormikValues, { setSubmitting, resetForm }) => {
      try {
        let paymentFactors = [];
        switch (programType) {
          case ProgramType.doePr:
            paymentFactors = [
              { escalationRate: 0, value: values.zeroEsc },
              { escalationRate: 0.01, value: values.oneEsc },
            ];
            break;
          default:
            paymentFactors = [
              { escalationRate: 0, value: values.zeroEsc },
              { escalationRate: 0.0099, value: values.zeroNineNineEsc },
              { escalationRate: 0.0199, value: values.oneNineNineEsc },
              { escalationRate: 0.0299, value: values.twoNineNineEsc },
              { escalationRate: 0.035, value: values.threeFiveEsc },
            ];
        }
        await createConstructionAdder({
          data: {
            adderType: adderType,
            name: values.name,
            paymentFactors,
          },
          programType: programType as ProgramType,
        }).unwrap();
        toast.success('Adder created successfully');
        resetForm();
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error creating adder');
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Modal ariaLabelledBy="createConstructionAdder" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header
        id="createConstructionAdder"
        title={`Add ${adderMap[ProgramType.solar][adderType as AdderType]} Modifier`}
        onDismiss={closeModal}
      />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body background="secondary">
          <Box direction="column" childGap="md">
            <TextInput
              type="text"
              label="Option Name"
              name="name"
              id="name"
              value={formik.values.name}
              onChange={(event) => formik.setFieldValue('name', event.target.value)}
              isRequired
              onBlur={formik.handleBlur}
              error={formik.touched.name && (formik.errors.name as string)}
            />
            <Box childGap="lg">
              <Box fontWeight="medium" color="body-primary">
                Backup Battery Modifiers
              </Box>
              {programType === ProgramType.solar && (
                <>
                  <Box direction="row" gap="lg">
                    <TextInput
                      type="text"
                      label="0% Escalator"
                      name="zeroEsc"
                      id="zeroEsc"
                      value={formik.values.zeroEsc}
                      onChange={(event) => formik.setFieldValue('zeroEsc', event.target.value)}
                      isRequired
                      onBlur={formik.handleBlur}
                      error={formik.touched.zeroEsc && (formik.errors.zeroEsc as string)}
                    />
                    <TextInput
                      type="text"
                      label="0.99% Escalator"
                      name="zeroNineNineEsc"
                      id="zeroNineNineEsc"
                      value={formik.values.zeroNineNineEsc}
                      onChange={(event) => formik.setFieldValue('zeroNineNineEsc', event.target.value)}
                      isRequired
                      onBlur={formik.handleBlur}
                      error={formik.touched.zeroNineNineEsc && (formik.errors.zeroNineNineEsc as string)}
                    />
                  </Box>
                  <Box direction="row" gap="lg">
                    <TextInput
                      type="text"
                      label="1.99% Escalator"
                      name="oneNineNineEsc"
                      id="oneNineNineEsc"
                      value={formik.values.oneNineNineEsc}
                      onChange={(event) => formik.setFieldValue('oneNineNineEsc', event.target.value)}
                      isRequired
                      onBlur={formik.handleBlur}
                      error={formik.touched.oneNineNineEsc && (formik.errors.oneNineNineEsc as string)}
                    />
                    <TextInput
                      type="text"
                      label="2.99% Escalator"
                      name="twoNineNineEsc"
                      id="twoNineNineEsc"
                      value={formik.values.twoNineNineEsc}
                      onChange={(event) => formik.setFieldValue('twoNineNineEsc', event.target.value)}
                      isRequired
                      onBlur={formik.handleBlur}
                      error={formik.touched.twoNineNineEsc && (formik.errors.twoNineNineEsc as string)}
                    />
                  </Box>
                  <Box direction="row" gap="lg">
                    <TextInput
                      type="text"
                      label="3.5% Escalator"
                      name="threeFiveEsc"
                      id="threeFiveEsc"
                      value={formik.values.threeFiveEsc}
                      onChange={(event) => formik.setFieldValue('threeFiveEsc', event.target.value)}
                      isRequired
                      onBlur={formik.handleBlur}
                      error={formik.touched.threeFiveEsc && (formik.errors.threeFiveEsc as string)}
                    />
                  </Box>
                </>
              )}
              {programType === ProgramType.doePr && (
                <Box direction="row" gap="lg">
                  <TextInput
                    type="text"
                    label="0% Escalator"
                    name="zeroEsc"
                    id="zeroEsc"
                    value={formik.values.zeroEsc}
                    onChange={(event) => formik.setFieldValue('zeroEsc', event.target.value)}
                    isRequired
                    onBlur={formik.handleBlur}
                    error={formik.touched.zeroEsc && (formik.errors.zeroEsc as string)}
                  />
                  <TextInput
                    type="text"
                    label="1% Escalator"
                    name="oneEsc"
                    id="oneEsc"
                    value={formik.values.oneEsc}
                    onChange={(event) => formik.setFieldValue('oneEsc', event.target.value)}
                    isRequired
                    onBlur={formik.handleBlur}
                    error={formik.touched.oneEsc && (formik.errors.oneEsc as string)}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as="button"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
            variant="secondary"
            tone="neutral"
            size="md"
            isLoading={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button size="md" type="submit" isLoading={formik.isSubmitting}>
            Add Option
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
