import { ActivationDocumentTypes, Flag, InstallationPackagePhotoCategoryTitles, documentTitles } from "types"
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';
import FormSection from "../Forms/FormSection"
import { Alert } from "@palmetto/palmetto-components";

const missingDocumentCategories = {
	installPhotoDocumentation: 'Install Photo Documentation',
	designPackageDocuments: 'Design Package Documents',
};

export const ConditionallyApprovedDocs = ({ flaggedDocs }: { flaggedDocs: Flag[] }) => {

	const flaggedMissingDocs = flaggedDocs
		?.map((flag: Flag & { category?: string }) => {
			const missingDoc =
				(Object.values({
					...documentTitles,
					installPhotoDocumentation: missingDocumentCategories.installPhotoDocumentation,
					designPackageDocuments: missingDocumentCategories.designPackageDocuments,
				}).includes(flag.category || '') && !flag.resolved)
					? flag.category
					: (Object.values(InstallationPackagePhotoCategoryTitles).includes(flag.dataKey || '') && !flag.resolved)
						? flag.dataKey
						: undefined;

			return missingDoc
				? {
					missingDoc: Object.values(missingDocumentCategories).includes(missingDoc)
						? documentTitles[flag.type as keyof typeof documentTitles] || missingDoc
						: flag.type
							? documentTitles[flag.type as keyof typeof documentTitles] 
							: flag.category ? flag.category : flag.dataKey,
					note: flag.note,
				}
				: undefined;
		}).filter((missingDoc) => missingDoc !== undefined);

	const MissingDocsList = () => {
		return (
			<ul>
				{flaggedMissingDocs.map((flaggedItem: any, index: number) => (
					flaggedItem &&
					<li key={`${flaggedItem.missingDoc}-${index}`}>
						{flaggedItem.missingDoc}{flaggedItem.note ? `: ${flaggedItem.note}` : ''}
					</li>
				))}
			</ul>
		);
	};

	return (
		<FormSection title="Conditional Approval Information" description="Provide necessary documentation, files or notes that address conditional approval requirements">
			<Alert variant="warning"
				hasIcon
				fontSize="xs"
				title={`The installation package for this project was conditionally approved. 
							We need final proof of the following submitted along with the activation package for Milestone Approval:`}
				message={<MissingDocsList />}
			/>
			<DocumentList
				documentTypeFilter={[ActivationDocumentTypes.conditionalApproval]}
				title="Conditional Approval Documents"
				showTypeLabelOnItems={false}
				baseCategory={ActivationDocumentTypes.conditionalApproval}
			/>
		</FormSection>
	)
}
