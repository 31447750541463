import { Box } from '@palmetto/palmetto-components';
import EventBy from './EventBy';

const SabalPriceSheetsMappingsActivity = ({ event, label }: { event: any; label: string }) => {
  const states = Array.from(
    new Set(
      event.data.mappings.map((element: any) => {
        return element.state;
      }),
    ),
  );
  const organizations = Array.from(
    new Set(
      event.data.mappings.map((element: any) => {
        return element.organizationName;
      }),
    ),
  );
  const utility = Array.from(
    new Set(
      event.data.mappings.map((element: any) => {
        return element?.utility?.name;
      }),
    ),
  );
  return (
    <Box display="block">
      <EventBy event={event} /> {label} a Price Sheet Mapping:
      <br />
      <Box width={'100'}>
        <Box direction={'row'}>
          <Box fontWeight={'black'} margin={'0 xs 0 0'}>
            {organizations.length > 1 ? 'Organizations' : 'Organization'}:
          </Box>{' '}
          {organizations.join(', ')}
        </Box>
        <Box direction={'row'}>
          <Box fontWeight={'black'} margin={'0 xs 0 0'}>
            {states.length > 1 ? 'States' : 'State'}:
          </Box>{' '}
          {states.join(', ')}
        </Box>
        <Box direction={'row'}>
          <Box fontWeight={'black'} margin={'0 xs 0 0'}>
            {utility.length > 1 ? 'Utilities' : 'Utility'}:
          </Box>{' '}
          {utility.join(', ')}
        </Box>
      </Box>
    </Box>
  );
};

export default SabalPriceSheetsMappingsActivity;
