import { PackageRejectionInfo } from "types"

type RejectionReasonsListType = {
	rejectionInfo?: PackageRejectionInfo;
}

export const RejectionReasonsList = ({ rejectionInfo }: RejectionReasonsListType) => {

	console.log({ rejectionInfo });
	return (
		<ul>
			{rejectionInfo?.option && (
				<li>
					{rejectionInfo.option}
					{rejectionInfo.rejectionReasons && rejectionInfo.rejectionReasons.length > 0 && (
						<ul>
							{rejectionInfo.rejectionReasons.map((reason, reasonIndex: number) => (
								<li key={`reason-${reasonIndex}`}>
									{reason.reason && <span>{String(reason.reason)}</span>}
									{reason.subReasons && reason.subReasons.length > 0 && (
										<ul>
											{reason.subReasons.map((subReason, subReasonIndex: number) => (
												<li key={`subReason-${reasonIndex}-${subReasonIndex}`}>
													{String(subReason)}
												</li>
											))}
										</ul>
									)}
								</li>
							))}
						</ul>
					)}
				</li>
			)}
		</ul>
	);
}