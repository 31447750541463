import { Box, FormikTextInput, TextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext, FormikErrors, FormikTouched } from 'formik';
import { usePhoneInput } from 'react-international-phone';

export const ApplicantInformation = ({
  isSubmitting,
  prefix,
  disabledFields,
}: {
  isSubmitting: boolean;
  prefix: string;
  disabledFields: string[];
}) => {
  const { errors, handleBlur, values, setFieldValue, touched } = useFormikContext<any>();
  const initialPhoneNumber = values[prefix]?.phoneNumber?.startsWith('+1')
    ? values[prefix].phoneNumber
    : `+1 ${values[prefix]?.phoneNumber ?? ''}`;
  const { inputValue: phoneNumber, handlePhoneValueChange } = usePhoneInput({
    value: initialPhoneNumber,
    onChange: (data) => {
      setFieldValue(`${prefix}.phoneNumber`, data.phone);
    },
  });

  const prefixTouched = touched[prefix] as FormikTouched<any>;
  const prefixErrors = errors[prefix] as FormikErrors<any>;

  return (
    <Box childGap={{ base: 'lg', desktop: 'xl' }}>
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
      >
        <Field
          type="text"
          label="First Name"
          name={`${prefix}.firstName`}
          id={`${prefix}.firstName`}
          component={FormikTextInput}
          isRequired
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes('firstName')}
        />
        <Field
          type="text"
          label="Middle Name"
          name={`${prefix}.middleName`}
          id={`${prefix}.middleName`}
          component={FormikTextInput}
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes('middleName')}
        />
        <Field
          type="text"
          label="Last Name"
          name={`${prefix}.lastName`}
          id={`${prefix}.lastName`}
          isRequired
          component={FormikTextInput}
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes('lastName')}
        />
      </Box>
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
      >
        <TextInput
          label="Phone Number"
          name={`${prefix}.phoneNumber`}
          id={`${prefix}.phoneNumber`}
          isRequired
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes('phoneNumber')}
          onChange={handlePhoneValueChange}
          value={phoneNumber}
          placeholder="+1 (555) 555-5555"
          onBlur={handleBlur}
          error={prefixTouched?.phoneNumber && (prefixErrors?.phoneNumber as string)}
        />
        <Field
          type="text"
          label="Email"
          name={`${prefix}.email`}
          id={`${prefix}.email`}
          component={FormikTextInput}
          isRequired
          autoComplete="off"
          isDisabled={isSubmitting || disabledFields.includes('email')}
        />
      </Box>
    </Box>
  );
};
