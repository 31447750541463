import { Box } from '@palmetto/palmetto-components';
import { PaymentPlan } from './PaymentPlan';
import BankAccount from './BankAccount';
import { OrganizationNode } from '@/types/Organizations';

interface PaymentSettingsProps {
  organization: OrganizationNode;
  isLoading?: boolean;
}

export const PaymentSettings = ({ organization }: PaymentSettingsProps) => {
  return (
    <Box
      childGap={{
        base: 'sm',
        tablet: 'md',
        desktop: 'lg',
      }}
      padding="lg 0 0 0"
    >
      <Box flex="auto">
        <PaymentPlan organization={organization} />
      </Box>
      <Box flex="auto">
        <BankAccount organization={organization} />
      </Box>
    </Box>
  );
};
