import { Field, FieldArray, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { Box, BoxProps, Button, FormikSelectInputNative, FormikTextInput } from '@palmetto/palmetto-components';
import { useGetApprovedVendorsQuery } from '../../services/approvedVendors';
import { useCallback, useMemo, useEffect, ChangeEvent } from 'react';
import { ApprovedVendorSettings, ProgramType } from 'types';
import {
  mapAvlManufacturerOptions,
  mapAvlModelNumberOptions,
  maybePrependCustomFreetextOption,
} from './AvlFormUtils';
import { EquipmentDetailsDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';

interface EquipmentDetailsProps {
  hideTitleAndDescription?: boolean;
  isLoading?: boolean;
  boxProps?: BoxProps;
  programType: ProgramType;
}

export const EquipmentDetailsFormSection = ({
  hideTitleAndDescription = false,
  isLoading,
  programType,
}: EquipmentDetailsProps) => {
  const { isSubmitting, values, setFieldTouched, setFieldValue } = useFormikContext<any>();
  const { inverterModels } = values;
  const { data: approvedVendorList, isLoading: isAppSettingsLoading } = useGetApprovedVendorsQuery({ programType });
  const { inverter, panel } =
    approvedVendorList ?? ({ inverter: [], panel: [] } as unknown as ApprovedVendorSettings);
  const isAnythingLoadingOrSubmitting = isLoading || isAppSettingsLoading || isSubmitting;

  const inverterManufacturerOptions = useMemo(() => {
    const options = mapAvlManufacturerOptions(inverter);
    return maybePrependCustomFreetextOption(options, values.inverterManufacturer);
  }, [inverter, values.inverterManufacturer]);

  const inverterModelOptions = useMemo(() => {
    return inverterModels.map((_inverter: any, index: number) => {
      const options = mapAvlModelNumberOptions(inverter, values.inverterManufacturer);
      return maybePrependCustomFreetextOption(options, values.inverterModels[index]?.model);
    });
  }, [inverter, values.inverterManufacturer, values.inverterModels]);

  const panelManufacturerOptions = useMemo(() => {
    const options = mapAvlManufacturerOptions(panel);
    return maybePrependCustomFreetextOption(options, values.panelManufacturer);
  }, [panel, values.panelManufacturer]);

  const panelModelOptions = useMemo(() => {
    const options = mapAvlModelNumberOptions(panel, values.panelManufacturer);
    return maybePrependCustomFreetextOption(options, values.panelModel);
  }, [panel, values.panelManufacturer, values.panelModel]);

  const addInverter = useCallback(() => {
    setFieldValue('inverterModels', [...inverterModels, { model: '', count: 1 }]);
    setFieldTouched('inverterModels', true);
  }, [inverterModels, setFieldValue, setFieldTouched]);

  const removeInverter = useCallback(
    (index: number) => {
      inverterModels.splice(index, 1);
      setFieldTouched('inverterModels', true);
    },
    [inverterModels, setFieldTouched],
  );

  const setPanelCount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const panelCount = Number(event.target.value);
      if (!Number.isNaN(panelCount)) {
        setFieldValue('totalPanelCount', panelCount);
      }
    },
    [setFieldValue],
  );

  const renderInverterModels = useCallback(
    () => (
      <Box childGap={{ base: 'lg', desktop: 'xl' }}>
        {inverterModels.map((_inverter: string, index: number) => (
          <Box key={index} direction={{ base: 'column', tablet: 'row' }} childGap={{ base: 'lg', desktop: 'xl' }}>
            <Field
              label={`Inverter ${index + 1}`}
              name={`inverterModels.${index}.model`}
              id={`inverterModels.${index}.model`}
              component={FormikSelectInputNative}
              options={inverterModelOptions[index]}
              isDisabled={isAnythingLoadingOrSubmitting}
              width="55"
            />
            <Field
              label="Count"
              name={`inverterModels.${index}.count`}
              id={`inverterModels.${index}.count`}
              component={FormikTextInput}
              isDisabled={isAnythingLoadingOrSubmitting}
              maxWidth="lg"
              width="30"
            />
            {index > 0 && (
              <Button
                variant="secondary"
                tone="neutral"
                onClick={() => removeInverter(index)}
                isDisabled={isAnythingLoadingOrSubmitting}
                iconPrefix="remove"
                size="sm"
                style={{ margin: '25px 0 0 0' }}
              />
            )}
          </Box>
        ))}
      </Box>
    ),
    [inverterModelOptions, inverterModels, isAnythingLoadingOrSubmitting, removeInverter],
  );

  useEffect(() => {
    setFieldTouched('panelModel', true);
  }, [values.panelManufacturer, setFieldTouched]);

  const EquipmentDetailsFields = (
    <Box width="100">
      <Box childGap={{ base: 'lg', desktop: 'xl' }} direction="row">
        <Field
          label="Panel Manufacturer"
          name="panelManufacturer"
          id="panelManufacturer"
          options={panelManufacturerOptions}
          component={FormikSelectInputNative}
          isDisabled={isAnythingLoadingOrSubmitting}
        />
        <Field
          label="Panel Model"
          name="panelModel"
          id="panelModel"
          component={FormikSelectInputNative}
          options={panelModelOptions}
          isDisabled={isAnythingLoadingOrSubmitting}
        />
        <Field
          label="Panel Quantity"
          name="totalPanelCount"
          id="totalPanelCount"
          component={FormikTextInput}
          isDisabled={isAnythingLoadingOrSubmitting}
          onChange={setPanelCount}
          maxWidth="xl"
        />
      </Box>
      <Box childGap={{ base: 'lg', desktop: 'xl' }} direction="row" margin={'lg 0 0 0'}>
        <Field
          label="Inverter Manufacturer"
          name="inverterManufacturer"
          id="inverterManufacturer"
          component={FormikSelectInputNative}
          options={inverterManufacturerOptions}
          isDisabled={isAnythingLoadingOrSubmitting}
          width="30"
        />
        <Box width="70" childGap={{ base: 'lg', desktop: 'xl' }}>
          <Box childGap={{ base: 'lg', desktop: 'xl' }}>
            <FieldArray name="inverters" render={renderInverterModels} />
          </Box>
          <Box display="flex" direction="row" childGap="xs">
            <Button
              variant="secondary"
              tone="neutral"
              onClick={addInverter}
              isDisabled={isAnythingLoadingOrSubmitting}
              iconPrefix="add"
              size="sm"
            >
              inverter
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  return (
    <Box>
      <FormSection
        {...(!hideTitleAndDescription && { title: 'Equipment', description: EquipmentDetailsDescription })}
      >
        {EquipmentDetailsFields}
      </FormSection>
    </Box>
  );
};
