import { Helmet } from 'react-helmet';
import { Box } from '@palmetto/palmetto-components';
import {
  useGetOrganizationDetailsQuery,
  useGetOrganizationPaymentSettingsQuery,
} from '../../../services/organizations';
import PageHeader from '../../PageHeader';
import { Link, NavLink, Route, Routes, useParams } from 'react-router-dom';
import OrganizationSettings from './OrganizationSettings';
import { Payments } from './Payments/Payments';
import { useFlags } from 'launchdarkly-react-client-sdk';
import usePermissions from '../../../hooks/usePermissions';
import { UserPermissions } from 'types';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../../auth/requirePermissions';
import Emails from './Emails/Emails';
import SplitPay from './SplitPay/SplitPay';
import { Pricing } from './Pricing/Pricing';
import { MainContainer } from '@/components/MainContainer';

export function OrganizationDetails() {
  const { alias } = useParams<{ alias: any }>();
  const { data: organization, isLoading } = useGetOrganizationDetailsQuery({ alias });
  const { data: paymentSettings } = useGetOrganizationPaymentSettingsQuery({ alias });
  const { enablePayouts = false } = useFlags();
  const permissions = usePermissions();

  // lightReachPaymentAdmin can always see tab if FF is enabled
  // others only see the tab if a document exists
  const showPaymentsTab =
    enablePayouts &&
    ((paymentSettings &&
      permissions?.some((permission) =>
        [UserPermissions.lightReachOrgPaymentAdmin, UserPermissions.lightReachOrgPaymentViewer].includes(permission),
      )) ||
      permissions.some((permission: string) => permission === UserPermissions.lightReachPaymentAdmin));

  return (
    <>
      <Helmet>
        <title>{isLoading ? '...' : organization?.name || 'organization'} | Organizations | Settings</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <PageHeader
          title={isLoading ? '...' : organization?.name || 'organization'}
          eyebrow={<Link to="/settings/organizations">Organizations</Link>}
        />
        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator">
          <NavLink
            to={`/settings/organizations/${organization?.alias}`}
            style={{ textDecoration: 'none', fontWeight: '500' }}
            end
          >
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary-500' }}
                style={{ transitionDuration: '0s' }}
              >
                Settings
              </Box>
            )}
          </NavLink>
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={['admin']}>
              <NavLink
                to={`/settings/organizations/${organization?.alias}/pricing/`}
                style={{ textDecoration: 'none', fontWeight: '500' }}
              >
                {({ isActive }) => (
                  <Box
                    as="span"
                    padding="md"
                    className={isActive ? 'tab-active' : ''}
                    hover={{ color: 'primary-500' }}
                    style={{ transitionDuration: '0s' }}
                  >
                    Pricing
                  </Box>
                )}
              </NavLink>
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
          {showPaymentsTab && (
            <RequirePermissions permissions={['admin']}>
              <NavLink
                to={`/settings/organizations/${organization?.alias}/payments`}
                style={{ textDecoration: 'none', fontWeight: '500' }}
              >
                {({ isActive }) => (
                  <Box
                    as="span"
                    padding="md"
                    className={isActive ? 'tab-active' : ''}
                    hover={{ color: 'primary' }}
                    style={{ transitionDuration: '0s' }}
                  >
                    Payments
                  </Box>
                )}
              </NavLink>
            </RequirePermissions>
          )}
          <NavLink
            to={`/settings/organizations/${organization?.alias}/emails`}
            style={{ textDecoration: 'none', fontWeight: '500' }}
          >
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Emails
              </Box>
            )}
          </NavLink>
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={['admin']}>
              <NavLink
                to={`/settings/organizations/${organization?.alias}/split-pay`}
                style={{ textDecoration: 'none', fontWeight: '500' }}
                end
              >
                {({ isActive }) => (
                  <Box
                    as="span"
                    padding="md"
                    className={isActive ? 'tab-active' : ''}
                    hover={{ color: 'primary-500' }}
                    style={{ transitionDuration: '0s' }}
                  >
                    Greentech Direct Pay
                  </Box>
                )}
              </NavLink>
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        </Box>

        <Routes>
          <Route path="/" element={<OrganizationSettings organization={organization} isLoading={isLoading} />} />
          {showPaymentsTab && organization && (
            <Route path="/payments/:paymentsType?/*" element={<Payments organization={organization} />} />
          )}

          <Route path="/pricing/:pricingType?/*" element={<Pricing />} />

          {organization && <Route path="/emails" element={<Emails organization={organization} />} />}
          {organization && <Route path="/split-pay" element={<SplitPay organization={organization} />} />}
        </Routes>
      </MainContainer>
    </>
  );
}
