import { Box, Button, Spinner } from '@palmetto/palmetto-components';
import { ActivityList } from '../../Activity/ActivityList';
import { useGetHistoryQuery } from '../../../services/history';
import { SabalEventType } from 'types';
import { useState } from 'react';

const pricingTypes = [
  SabalEventType.sabalHoldbackCreated,
  SabalEventType.sabalHoldbackUpdated,
  SabalEventType.sabalModifierCreated,
  SabalEventType.sabalModifierUpdated,
  SabalEventType.sabalPriceSheetsMappingsCreated,
  SabalEventType.sabalPriceSheetsMappingsArchived,
  SabalEventType.sabalConstructionAdderCreated,
  SabalEventType.sabalConstructionAdderUpdated,
  SabalEventType.sabalPriceSheetCreated,
  SabalEventType.sabalPriceSheetUpdated,
];

const PAGE_SIZE = 20;
export const PricingHistory = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useGetHistoryQuery({
    types: pricingTypes,
    pageNum: page,
    pageSize: PAGE_SIZE,
  });
  const response = data?.data;
  return (
    <>
      <Box direction="row" childGap="3xl">
        <Box flex="auto">
          {isLoading || isFetching ? (
            <Spinner size="lg" />
          ) : response?.length > 0 ? (
            <Box gap="lg">
              <ActivityList activities={response} />
              <Box direction="row" childGap="xs" padding="0 lg lg lg">
                <Button
                  isDisabled={!page || page === 1}
                  onClick={() => setPage((page) => page - 1)}
                  variant="secondary"
                  tone="neutral"
                  iconPrefix="caret-left"
                  size="sm"
                >
                  Prev
                </Button>
                <Button
                  isDisabled={response && page * PAGE_SIZE >= data.total}
                  onClick={() => setPage((page) => page + 1)}
                  variant="secondary"
                  tone="neutral"
                  iconSuffix="caret-right"
                  size="sm"
                >
                  Next
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box fontSize="sm" color="body-secondary">
                No history exists at the moment.
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
