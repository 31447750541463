import { Field, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { Box, FormikSelectInputNative, FormikRadioGroup, FormLabel, BoxProps } from '@palmetto/palmetto-components';
import { useGetApprovedVendorsQuery } from '../../services/approvedVendors';
import { useMemo } from 'react';
import { ApprovedVendorSettings, ProgramType } from 'types';
import { mapAvlManufacturerOptions, maybePrependCustomFreetextOption, Option } from './AvlFormUtils';

interface MountingHardwareProps {
  className?: string;
  hideTitleAndDescription?: boolean;
  isLoading?: boolean;
  boxProps?: BoxProps;
  programType: ProgramType;
}

const mountingTypeOptions: Option[] = [
  { value: 'roof', label: 'Roof Mount', id: 'roof' },
  { value: 'ground', label: 'Ground Mount', id: 'ground' },
  { value: 'combination', label: 'Roof/Ground Combination', id: 'combination' },
];

export const MountingHardwareFormSection = ({
  className,
  hideTitleAndDescription = false,
  isLoading,
  boxProps,
  programType,
}: MountingHardwareProps) => {
  const { isSubmitting, values } = useFormikContext<any>();
  const { data: approvedVendorList, isLoading: isAppSettingsLoading } = useGetApprovedVendorsQuery({ programType });
  const { mounting } = approvedVendorList ?? ({ mounting: [] } as unknown as ApprovedVendorSettings);
  const isAnythingLoadingOrSubmitting = isLoading || isAppSettingsLoading || isSubmitting;
  const boxValues = boxProps ?? { width: '100%', margin: 'sm' };

  const manufacturerOptions = useMemo(() => {
    const options = mapAvlManufacturerOptions(mounting);
    return maybePrependCustomFreetextOption(options, values.mountingManufacturer);
  }, [mounting, values.mountingManufacturer]);

  const MountingHardwareFields = (
    <Box width="100%">
      <Box
        direction={{ base: 'column', tablet: 'row' }}
        childGap={{ base: 'lg', desktop: 'xl' }}
        width={boxValues.width}
        margin={boxValues.margin}
      >
        <Box childGap={{ base: 'lg', desktop: 'lg' }} width="47%">
          <FormLabel inputId="mountingType">Mounting Type</FormLabel>
          <Field
            label="Mounting Type"
            name="mountingType"
            id="mountingType"
            component={FormikRadioGroup}
            options={mountingTypeOptions}
            isDisabled={isAnythingLoadingOrSubmitting}
          />
        </Box>
        <Box childGap={{ base: 'lg', desktop: 'xl' }} width="49%">
          <Field
            label="Hardware Manufacturer"
            name="mountingManufacturer"
            id="mountingManufacturer"
            component={FormikSelectInputNative}
            options={manufacturerOptions}
            isDisabled={isAnythingLoadingOrSubmitting}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <FormSection
        {...(!hideTitleAndDescription ? { title: 'Mounting', description: 'Optional for NTP' } : { className })}
      >
        {MountingHardwareFields}
      </FormSection>
    </Box>
  );
};
