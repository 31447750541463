import { Alert, Box, Button, Icon, toast, useOpenClose } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { useGetQueueByAccountIdAndTypeQuery, useUpdateQueueMutation } from '../../services/queues';
import { PackageRejectionInfo, QueueStatus, QueueType } from 'types';
import { useCallback, useContext, useMemo, useState } from 'react';
import { RemoveClaimModal } from '../Queues/RemoveClaimModal';
import { useAuth } from '../auth/authProvider';
import DateTimeDisplay from '../DateTime';
import { FlagCount } from '../Queues/CompleteQueueReview/FlagCount';
import { QueueFlagsContext } from '../QueueFlags/QueueFlagsContext';
import './QueueBanner.css';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import { RejectionReasonsList } from '../Reject/RejectionReasonsList';
import { BaseRejectionReasons } from '@/types/BasePackageTypes';

const statusMap: Record<string, string> = {
  submitted: 'New',
  resubmitted: 'Re-Review',
  onHold: 'On Hold',
  inProgress: 'In Progress',
  rejected: 'Rejected',
  approved: 'Approved',
  conditionallyApproved: 'Conditionally Approved',
};

const getMostRecentObj = (array: any[]) => {
  return array?.reduce((prev: any, current: any) => {
    return prev.date > current.date ? prev : current;
  });
};

export const QueueBanner = ({
  queueType,
  setIsNotesOpen,
  isFinCoUser,

}: {
  queueType: QueueType;
  setIsNotesOpen: (isNotesOpen: boolean) => void;
  isFinCoUser: boolean;
}) => {
  const { id = '' } = useParams<{ id: string }>();
  const { user } = useAuth();
  const { getAllFlags } = useContext(QueueFlagsContext);

  const flags = useMemo(() => {
    if (getAllFlags) {
      return getAllFlags(false);
    } else {
      return [];
    }
  }, [getAllFlags]);
  const flagsCount = flags.length;

  const { data: queue, isLoading: isQueueLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: queueType,
  });
  const [modalData, setModalData] = useState<Record<string, string> | undefined>();
  const { isOpen: isModalOpen, handleClose: handleModalClose, handleOpen: openModal } = useOpenClose();
  const [updateQueue] = useUpdateQueueMutation();

  const removeClaim = useCallback(() => {
    setModalData({ assigneeName: queue.assignee.name, id: queue.id });
    openModal();
  }, [openModal, queue]);

  const claimQueue = useCallback(async () => {
    await updateQueue({
      data: { assigneeId: user?.sub },
      id: queue.id,
    }).unwrap();
    toast.success('Queue claimed');
  }, [updateQueue, user, queue]);

  const mostRecentHoldOrRejectionObj =
    queue?.status === QueueStatus.rejected
      ? getMostRecentObj(queue?.rejectionReasons)
      : queue?.status === QueueStatus.onHold
        ? getMostRecentObj(queue?.holdReasons)
        : undefined;

  const approvalNote =
    [QueueStatus.approved, QueueStatus.conditionallyApproved].includes(queue?.status) && queue?.approvalNotes
      ? getMostRecentObj(queue.approvalNotes)
      : undefined;

  const mostRecentRejectionInfo = queue?.status === QueueStatus.rejected && queue?.rejectionReasons ? getMostRecentObj(queue?.rejectionReasons).rejectionInfo : {};

  const rejectedInfoArray: PackageRejectionInfo[] = mostRecentRejectionInfo ? Object.values(mostRecentRejectionInfo) : [];
  return (
    <>
      <RemoveClaimModal isModalOpen={isModalOpen} closeModal={handleModalClose} data={modalData} />
      {!isQueueLoading && queue ? (
        <Box
          shadow="sm"
          radius="md"
          direction="row"
          alignItems="center"
          width="100"
          background="primary"
          overflow="hidden"
        >
          <Box borderColor="separator" borderWidth="0 xs 0 0" justifyContent="center" width="2xl" padding="lg">
            <Box childGap="xs" alignItems="center" className={queue.status}>
              <Box alignItems="center" childGap="xs">
                {queue.status === QueueStatus.onHold && <Icon name="pause" size="lg" color="warning-500" />}
                {queue.status === QueueStatus.rejected && <Icon name="remove" size="lg" color="danger" />}
                {queue.status === QueueStatus.approved && <Icon name="check" size="lg" color="success" />}
                {queue.status === QueueStatus.conditionallyApproved && (
                  <Icon name="check" size="lg" color="success" />
                )}
                <Box textAlign="center" fontSize="sm" fontWeight="medium">
                  {statusMap[queue.status]}
                </Box>
              </Box>
              <Box>
                <DateTimeDisplay
                  value={queue.updatedAt}
                  showDuration
                  fontSize="xs"
                  dateFormat="DD h:mm:ss a"
                  inline
                />
              </Box>
            </Box>
          </Box>
          <Box direction={{ tablet: 'row', base: 'column' }} gap="lg" width="100" padding="md lg">
            {flagsCount > 0 ? <FlagCount count={flagsCount} /> : null}
            {approvalNote ? (
              <Box justifyContent="center" gap="xs">
                <Box direction="row" gap="xs">
                  {approvalNote?.note && <Box style={{ fontStyle: 'italic' }}>{approvalNote.note}</Box>}
                </Box>
                <Box fontSize="xs" direction="row" gap="2xs">
                  <DateTimeDisplay
                    value={approvalNote.date}
                    showDuration={false}
                    fontSize="xs"
                    dateFormat="MMM d, yyyy h:mm a"
                  />
                  <RequirePalmettoFinanceUser>by {approvalNote.user.name}</RequirePalmettoFinanceUser>
                </Box>
              </Box>
            ) : null}
            {[QueueStatus.rejected, QueueStatus.onHold].includes(queue.status) ? (
              <Box justifyContent="center" gap="xs" flex="auto">
                {mostRecentHoldOrRejectionObj && mostRecentHoldOrRejectionObj.reasons?.length ? (
                  <>
                    <Box direction="row" gap="xs">
                      <Box fontWeight="medium">{mostRecentHoldOrRejectionObj.reasons.map((reason: keyof typeof BaseRejectionReasons) =>
                        BaseRejectionReasons[reason]).join(' ,')}
                      </Box>
                      {mostRecentHoldOrRejectionObj?.note && (
                        <Box style={{ fontStyle: 'italic' }}>{`- "${mostRecentHoldOrRejectionObj.note}"`}</Box>
                      )}
                    </Box>
                    <Box>
                      {rejectedInfoArray && rejectedInfoArray?.length > 0 && <Box>
                        Reasons: {rejectedInfoArray.map((rejectedReasons: PackageRejectionInfo) =>
                          <RejectionReasonsList rejectionInfo={rejectedReasons} />)}
                      </Box>}
                    </Box>
                  </>
                ) : null}
                <Box fontSize="xs" direction="row" gap="2xs">
                  <DateTimeDisplay
                    value={mostRecentHoldOrRejectionObj.date}
                    showDuration={false}
                    fontSize="xs"
                    dateFormat="MMM d, yyyy h:mm a"
                  />
                  <RequirePalmettoFinanceUser>by {mostRecentHoldOrRejectionObj.user.name} </RequirePalmettoFinanceUser>
                </Box>
              </Box>
            ) : null}
            {isFinCoUser ? (
              <>
                {[
                  QueueStatus.inProgress,
                  QueueStatus.resubmitted,
                  QueueStatus.submitted,
                  QueueStatus.onHold,
                  QueueStatus.rejected,
                ].includes(queue.status) &&
                  queue.assignee &&
                  queue.assignee.id ? (
                  <Box direction="row" gap="2xs" alignItems="center" fontSize="xs">
                    <Icon name="c-user" />
                    <Box>Claimed by {queue.assignee.name}</Box>
                    <Box>
                      <Button
                        variant="tertiary"
                        tone="neutral"
                        aria-label="remove"
                        iconSuffix="c-remove"
                        size="xs"
                        onClick={() => removeClaim()}
                      >
                        remove
                      </Button>
                    </Box>
                  </Box>
                ) : [
                  QueueStatus.inProgress,
                  QueueStatus.resubmitted,
                  QueueStatus.submitted,
                  QueueStatus.onHold,
                  QueueStatus.rejected,
                ].includes(queue.status) ? (
                  <Button variant="primary" tone="primary" onClick={() => claimQueue()}>
                    Claim For Review
                  </Button>
                ) : null}
              </>
            ) : null}
            <RequirePalmettoFinanceUser>
              <Button
                style={{ marginLeft: 'auto' }}
                variant="primary"
                tone="neutral"
                onClick={() => setIsNotesOpen(true)}
              >
                Notes & History
              </Button>
            </RequirePalmettoFinanceUser>
          </Box>
        </Box>
      ) : null}
      {rejectedInfoArray.length > 0 && <Alert
        variant="danger"
        title="Activation Package Rejection"
        message={
          <Box>Reasons: {rejectedInfoArray.map((rejectedReasons: PackageRejectionInfo, key: number) => <RejectionReasonsList key={key} rejectionInfo={rejectedReasons} />)}</Box>}
        hasIcon
      />}
    </>
  );
};
