import { Box } from '@palmetto/palmetto-components';
import { OrganizationNode } from '@/types/Organizations';
import { NavLink, Route, Routes, useParams } from 'react-router-dom';
import { PaymentSettings } from '@/components/settings/organizations/Payments/PaymentSettings';

interface PaymentsProps {
  organization: OrganizationNode;
  isLoading?: boolean;
}

export const Payments = ({ organization }: PaymentsProps) => {
  const { alias } = useParams();
  return (
    <Box direction="row" childGap="3xl">
      <Box>
        <NavLink
          to={`/settings/organizations/${alias}/payments/settings`}
          style={{ textDecoration: 'none', fontWeight: '500' }}
          key="settings"
          end
        >
          {({ isActive }) => (
            <Box
              as="span"
              padding="md"
              className={isActive ? 'tab-active' : ''}
              hover={{ color: 'primary' }}
              style={{ transitionDuration: '0s', borderBottom: 0 }}
            >
              Settings
            </Box>
          )}
        </NavLink>
      </Box>
      <Box flex="auto">
        <Routes>
          <Route path="/" element={<PaymentSettings organization={organization} />} />
        </Routes>
      </Box>
    </Box>
  );
};
