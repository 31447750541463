import { ObjectId } from 'mongodb';
import { Locale } from './index';
import { WithId } from 'mongodb';

export enum DisclosureType {
  creditApplication = 'creditApplication',
  prequalification = 'prequalification',
  txtMessageNotifications = 'txtMessageNotifications',
  termsAndConditions = 'termsAndConditions',
}

export interface DisclosureDefinition {
  id?: string | ObjectId;
  name: string;
  type: DisclosureType;
  version: string;
  versionNumber: number;
  summary: string;
  text: string;
  requireConsent: boolean;
  acceptanceLabel: string;
  language: Locale;
}

export type DisclosureDocument = WithId<DisclosureDefinition>;
