import { useGetPriceSheetsQuery, useLazyGetPriceSheetDetailsQuery } from '@/services/pricing';
import { Box, Icon, SelectInput } from '@palmetto/palmetto-components';
import { useFormikContext } from 'formik';
import { ProgramType } from 'types';
import { sortByLabel } from './util';
import { isFieldSelected } from './MappingFormFactory';
import { NavLink } from 'react-router-dom';
import { percentageFormatter } from '@/helpers/percentageFormatter';
import { useEffect } from 'react';
import { kwhPriceFormatter } from '@/helpers/currencyFormatter';

const pricingFields: Record<ProgramType, string[]> = {
  [ProgramType.solar]: ['pvOnlyMin', 'pvOnlyMax', 'absoluteCeiling', 'zeroEscalatorCeiling'],
  [ProgramType.doePr]: ['minSystemSize', 'maxSystemSize', 'minBatteryCapacity', 'maxBatteryCapacity'],
  [ProgramType.hvac]: [],
};

export const SolarPricingPartial = ({ priceSheetDetails }: { priceSheetDetails: any }) => {
  const formik = useFormikContext<any>();
  const kwhRates = new Set();
  priceSheetDetails?.prices?.forEach((p: any) => {
    kwhRates.add(p.kwhRate);
  });
  const kwhRateOptions = Array.from(kwhRates)
    .sort()
    .map((rate) => ({ label: kwhPriceFormatter.format(Number(rate)), value: rate }));

  return (
    <>
      <Box width="auto" flex="auto">
        <SelectInput
          id="pvOnlyMin"
          name="pvOnlyMin"
          label="PV-Only Min"
          value={formik.values.pvOnlyMin}
          onChange={(event) => formik.setFieldValue('pvOnlyMin', event.target.value)}
          options={kwhRateOptions}
          onBlur={formik.handleBlur}
          isDisabled={!kwhRateOptions.length || formik.isSubmitting}
          error={formik.touched.pvOnlyMin && (formik.errors.pvOnlyMin as string)}
        />
      </Box>
      <Box width="auto" flex="auto">
        <SelectInput
          id="pvOnlyMax"
          name="pvOnlyMax"
          label="PV-Only Max"
          value={formik.values.pvOnlyMax}
          onChange={(event) => formik.setFieldValue('pvOnlyMax', event.target.value)}
          options={kwhRateOptions}
          onBlur={formik.handleBlur}
          isDisabled={!kwhRateOptions.length || formik.isSubmitting}
          error={formik.touched.pvOnlyMax && (formik.errors.pvOnlyMax as string)}
        />
      </Box>
      <Box width="auto" flex="auto" maxWidth="xl">
        <SelectInput
          id="zeroEscalatorCeiling"
          name="zeroEscalatorCeiling"
          label="Zero Esc Max"
          value={formik.values.zeroEscalatorCeiling}
          onChange={(event) => formik.setFieldValue('zeroEscalatorCeiling', event.target.value)}
          options={kwhRateOptions}
          onBlur={formik.handleBlur}
          isDisabled={!kwhRateOptions.length || formik.isSubmitting}
          error={formik.touched.zeroEscalatorCeiling && (formik.errors.zeroEscalatorCeiling as string)}
        />
      </Box>
      <Box width="auto" flex="auto">
        <SelectInput
          id="absoluteCeiling"
          name="absoluteCeiling"
          label="Absolute Ceiling"
          value={formik.values.absoluteCeiling}
          onChange={(event) => formik.setFieldValue('absoluteCeiling', event.target.value)}
          options={kwhRateOptions}
          onBlur={formik.handleBlur}
          isDisabled={!kwhRateOptions.length || formik.isSubmitting}
          error={formik.touched.absoluteCeiling && (formik.errors.absoluteCeiling as string)}
        />
      </Box>
    </>
  );
};

export const DoePrPricingPartial = ({ priceSheetDetails }: { priceSheetDetails: any }) => {
  const formik = useFormikContext<any>();

  const systemSizeRates = new Set();
  const batteryCapacityRates = new Set();

  priceSheetDetails?.prices?.forEach((p: any) => {
    systemSizeRates.add(p.minSystemSize);
    systemSizeRates.add(p.maxSystemSize);
    batteryCapacityRates.add(p.minBatteryCapacity);
    batteryCapacityRates.add(p.maxBatteryCapacity);
  });

  const systemSizeRateOptions = Array.from(systemSizeRates)
    .sort((a: any, b: any) => a - b)
    .map((rate) => ({ label: rate, value: rate }));

  const batteryCapacityRateOptions = Array.from(batteryCapacityRates)
    .sort((a: any, b: any) => a - b)
    .map((rate) => ({ label: rate, value: rate }));

  return (
    <>
      <Box width="auto" flex="auto" maxWidth="xl">
        <SelectInput
          id="minSystemSize"
          name="minSystemSize"
          label="Min System Size"
          value={formik.values.minSystemSize}
          onChange={(event) => formik.setFieldValue('minSystemSize', event.target.value)}
          options={systemSizeRateOptions}
          onBlur={formik.handleBlur}
          isDisabled={!systemSizeRateOptions.length || formik.isSubmitting}
          error={formik.touched.minSystemSize && (formik.errors.minSystemSize as string)}
        />
      </Box>
      <Box width="auto" flex="auto">
        <SelectInput
          id="maxSystemSize"
          name="maxSystemSize"
          label="Max System Size"
          value={formik.values.maxSystemSize}
          onChange={(event) => formik.setFieldValue('maxSystemSize', event.target.value)}
          options={systemSizeRateOptions}
          onBlur={formik.handleBlur}
          isDisabled={!systemSizeRateOptions.length || formik.isSubmitting}
          error={formik.touched.maxSystemSize && (formik.errors.maxSystemSize as string)}
        />
      </Box>
      <Box width="auto" flex="auto">
        <SelectInput
          id="minBatteryCapacity"
          name="minBatteryCapacity"
          label="Min Battery Capacity"
          value={formik.values.minBatteryCapacity}
          onChange={(event) => formik.setFieldValue('minBatteryCapacity', event.target.value)}
          options={batteryCapacityRateOptions}
          onBlur={formik.handleBlur}
          isDisabled={!batteryCapacityRateOptions.length || formik.isSubmitting}
          error={formik.touched.minBatteryCapacity && (formik.errors.minBatteryCapacity as string)}
        />
      </Box>
      <Box width="auto" flex="auto">
        <SelectInput
          id="maxBatteryCapacity"
          name="maxBatteryCapacity"
          label="Max Battery Capacity"
          value={formik.values.maxBatteryCapacity}
          onChange={(event) => formik.setFieldValue('maxBatteryCapacity', event.target.value)}
          options={batteryCapacityRateOptions}
          onBlur={formik.handleBlur}
          isDisabled={!batteryCapacityRateOptions.length || formik.isSubmitting}
          error={formik.touched.maxBatteryCapacity && (formik.errors.maxBatteryCapacity as string)}
        />
      </Box>
    </>
  );
};

export const HVACPricingPartial = ({ priceSheetDetails }: { priceSheetDetails: any }) => {
  const formik = useFormikContext<any>();

  const termLengths = new Set();
  const systemCosts = new Set();

  priceSheetDetails?.prices?.forEach((p: any) => {
    termLengths.add(p.term);
    systemCosts.add(p.minSystemCost);
    systemCosts.add(p.maxSystemCost);
  });

  const termOptions = Array.from(termLengths)
    .sort((a: any, b: any) => a - b)
    .map((rate) => ({ label: rate, value: rate }));

  const systemCostOptions = Array.from(systemCosts)
    .sort((a: any, b: any) => a - b)
    .map((cost) => ({ label: cost, value: cost }));

  return (
    <>
      <Box width="auto" flex="auto" maxWidth="xl">
        <SelectInput
          id="minTerm"
          name="minTerm"
          label="Min Term"
          value={formik.values.minTerm}
          onChange={(event) => formik.setFieldValue('minTerm', event.target.value)}
          options={termOptions}
          onBlur={formik.handleBlur}
          isDisabled={!termOptions.length || formik.isSubmitting}
          error={formik.touched.minTerm && (formik.errors.minTerm as string)}
        />
      </Box>
      <Box width="auto" flex="auto">
        <SelectInput
          id="maxTerm"
          name="maxTerm"
          label="Max Term"
          value={formik.values.maxTerm}
          onChange={(event) => formik.setFieldValue('maxTerm', event.target.value)}
          options={termOptions}
          onBlur={formik.handleBlur}
          isDisabled={!termOptions.length || formik.isSubmitting}
          error={formik.touched.maxTerm && (formik.errors.maxTerm as string)}
        />
      </Box>
      <Box width="auto" flex="auto" maxWidth="xl">
        <SelectInput
          id="minSystemCost"
          name="minSystemCost"
          label="Min System Cost"
          value={formik.values.minSystemCost}
          onChange={(event) => formik.setFieldValue('minSystemCost', event.target.value)}
          options={systemCostOptions}
          onBlur={formik.handleBlur}
          isDisabled={!termOptions.length || formik.isSubmitting}
          error={formik.touched.minSystemCost && (formik.errors.minSystemCost as string)}
        />
      </Box>
      <Box width="auto" flex="auto">
        <SelectInput
          id="maxSystemCost"
          name="maxSystemCost"
          label="Max System Cost"
          value={formik.values.maxSystemCost}
          onChange={(event) => formik.setFieldValue('maxSystemCost', event.target.value)}
          options={systemCostOptions}
          onBlur={formik.handleBlur}
          isDisabled={!termOptions.length || formik.isSubmitting}
          error={formik.touched.maxSystemCost && (formik.errors.maxSystemCost as string)}
        />
      </Box>
    </>
  );
};

export const Pricing = ({ programType }: { programType: ProgramType }) => {
  const { data: priceSheetResults }: any = useGetPriceSheetsQuery({
    pageNum: 1,
    pageSize: 1000,
    programType,
  });
  const [trigger, { data: priceSheetDetails = {} }] = useLazyGetPriceSheetDetailsQuery();

  const formik = useFormikContext<any>();

  useEffect(() => {
    if (isFieldSelected(formik.values.priceSheet)) {
      trigger({ id: formik.values.priceSheet.value });
    }
  }, [formik.values.priceSheet, trigger]);

  const escRates = new Set();
  priceSheetDetails?.prices?.forEach((p: any) => {
    escRates.add(p.escalationRate);
  });

  const priceSheetOptions = priceSheetResults?.data
    ?.map((ps: any) => ({ label: ps.name, value: ps.id }))
    .sort(sortByLabel);
  const escRateOptions = Array.from(escRates)
    .sort()
    .map((rate) => ({ label: percentageFormatter.format(Number(rate)), value: rate }));

  const getProgramTypePricingPartial = () => {
    switch (programType) {
      case ProgramType.solar:
        return <SolarPricingPartial priceSheetDetails={priceSheetDetails} />;
      case ProgramType.doePr:
        return <DoePrPricingPartial priceSheetDetails={priceSheetDetails} />;
      case ProgramType.hvac:
        return <HVACPricingPartial priceSheetDetails={priceSheetDetails} />;
      default:
        return null;
    }
  };

  return (
    <Box childGap="xl" radius="md" borderWidth="xs" borderColor="separator" padding="lg">
      <Box as="h3" fontWeight="medium" fontSize="md">
        Pricing
      </Box>

      <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'xl' }} wrap>
        <Box childGap="sm" width={{ base: 'auto', desktop: '20' }}>
          <SelectInput
            id="priceSheet"
            name="priceSheet"
            label="Price Sheet"
            value={formik.values.priceSheet}
            onChange={(event) => {
              formik.setFieldValue('priceSheet', event.target.value);
              formik.setFieldValue('escMin', '');
              formik.setFieldValue('escMax', '');
              pricingFields[programType].forEach((field: string) => {
                formik.setFieldValue(field, '');
              });
            }}
            options={priceSheetOptions}
            onBlur={formik.handleBlur}
            isDisabled={formik.isSubmitting}
            error={formik.touched.priceSheet && (formik.errors.priceSheet as string)}
          />

          <Box>
            {isFieldSelected(formik.values.priceSheet) ? (
              <NavLink
                to={`/admin/pricing/${programType}/price-sheet/${formik.values.priceSheet.value}`}
                style={{ textDecoration: 'none', fontWeight: '500' }}
                target="_blank"
                end
              >
                {({ isActive }) => (
                  <Box
                    as="span"
                    direction="row"
                    className={isActive ? 'tab-active' : ''}
                    hover={{ color: 'primary' }}
                    style={{ transitionDuration: '0s', textDecoration: 'underline' }}
                    childGap="xs"
                    fontSize="xs"
                  >
                    <Box>View Price Sheet</Box>
                    <Icon name="launch-app" size="xs" />
                  </Box>
                )}
              </NavLink>
            ) : null}
          </Box>
        </Box>

        <Box direction={{ base: 'column', desktop: 'row' }} gap="xl" width="auto" flex="auto">
          <Box width="auto" flex="auto">
            <SelectInput
              id="escMin"
              name="escMin"
              label="Escalator Min"
              value={formik.values.escMin}
              onChange={(event) => formik.setFieldValue('escMin', event.target.value)}
              options={escRateOptions}
              onBlur={formik.handleBlur}
              isDisabled={!escRateOptions.length || formik.isSubmitting}
              error={formik.touched.escMin && (formik.errors.escMin as string)}
            />
          </Box>

          <Box width="auto" flex="auto">
            <SelectInput
              id="escMax"
              name="escMax"
              label="Escalator Max"
              value={formik.values.escMax}
              onChange={(event) => formik.setFieldValue('escMax', event.target.value)}
              options={escRateOptions}
              onBlur={formik.handleBlur}
              isDisabled={!escRateOptions.length || formik.isSubmitting}
              error={formik.touched.escMax && (formik.errors.escMax as string)}
            />
          </Box>
          {getProgramTypePricingPartial()}
        </Box>
      </Box>
    </Box>
  );
};
