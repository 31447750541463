import { NotesDrawer } from '../NotesDrawer/NotesDrawer';
import { toast, Alert } from '@palmetto/palmetto-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { NTPPackage as INTPPackage, DeepPartial, QueueStatus, QueueType } from 'types';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import { useSaveNTPPackageMutation, useSubmitNTPPackageMutation } from '../../services/ntpPackage';
import { QueueFlagsProvider } from '../QueueFlags/QueueFlagsContext';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import { QueueBanner } from '../MilestonePackagePartials/QueueBanner';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { Footer } from '../MilestonePackagePartials/Footer';
import { isErrorWithData } from '../../services/helpers';
import { PermitAttestment } from './PermitAttestment';
import { HVACProjectPhotos } from './HVACProjectPhotos';

interface NTPPackageProps {
  canSubmitPackage: boolean;
}

export const formatNTPPackagesData = (values: FormikValues): DeepPartial<INTPPackage> => {
  return {
    permitAttestment: {
      attestmentText: values.attestmentText,
    },
  };
};

const ntpSchema = yup.object().shape({
  attestmentText: yup.string().required('Permit attestment is required'),
});

export const NTPPackage = ({ canSubmitPackage }: NTPPackageProps) => {
  const navigate = useNavigate();
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);

  const { id = '', action = 'review' } = useParams<{ id: string; action: ReviewPackageActions }>();
  const { data: queue, isLoading: isQueueLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.ntpPackage,
  });
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);

  const [triggerSubmit] = useSubmitNTPPackageMutation();
  const [triggerSave] = useSaveNTPPackageMutation();

  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const isLoading = isAccountLoading || isQueueLoading;

  useEffect(() => {
    if (!action || !ReviewPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
    if (action === ReviewPackageActions.review && !isFinCoUser) {
      if (queue) {
        navigate(`/accounts/${id}/ntp-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${id}/ntp-package/create`, { replace: true });
      }
    }
  }, [id, navigate, action, isFinCoUser, queue]);

  const canSubmitReview = action === ReviewPackageActions.review && queue;

  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    try {
      const formattedData = formatNTPPackagesData(values) as INTPPackage;
      await triggerSubmit({ accountId: id, ntpPackage: formattedData }).unwrap();
      toast.success('Notice to Proceed Package Submitted');
      navigate(`/accounts/${id}`);
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error submitting notice to proceed package');
    } finally {
      setSubmitting(false);
    }
  };

  const handleSave = async ({ accountId, data }: { accountId: string; data: FormikValues }) => {
    const formattedData = formatNTPPackagesData(data) as INTPPackage;
    try {
      await triggerSave({ accountId, ntpPackage: formattedData }).unwrap();
      toast.success('Notice to Proceed Package Saved');
    } catch (e) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error saving Notice to Proceed Package');
      }
    }
  };

  const handleValidation = (values: any) => {
    const errors = {} as any;
    if (!values.attestmentText) {
      errors.attestmentText = 'Permit attestment is required';
    }
    return errors;
  };

  const queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser: boolean =
    !isFinCoUser &&
    queue &&
    (queue.status === QueueStatus.conditionallyApproved || queue.status === QueueStatus.approved);

  if (isLoading) {
    return (
      <MainContainer>
        <FormSection title="Loading...">
          <SkeletonBox height="25px" width="100" />
        </FormSection>
      </MainContainer>
    );
  }

  return (
    <QueueFlagsProvider queueId={queue?.id}>
      <NotesDrawer isOpen={isNotesOpen} setIsOpen={setIsNotesOpen} queueType={QueueType.ntpPackage} />
      <Helmet>
        <title>{`${account?.primaryApplicantName} Notice to Proceed Package`}</title>
      </Helmet>
      <MainContainer>
        {queue && (queue.status === QueueStatus.rejected || isFinCoUser) ? (
          <QueueBanner setIsNotesOpen={setIsNotesOpen} queueType={QueueType.ntpPackage} isFinCoUser={isFinCoUser} />
        ) : null}
        {action === ReviewPackageActions.review && !canSubmitReview ? (
          <Alert
            variant="warning"
            title="NTP Package Not Submitted"
            message="This notice-to-proceed package is not ready to be reviewed yet. It has not been submitted."
            hasIcon
          />
        ) : null}
        <Formik
          initialValues={{ attestmentText: account?.permitAttestment?.attestmentText || null }}
          validationSchema={ntpSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange={false}
          validate={handleValidation}
        >
          {() => (
            <Form>
              <HVACProjectPhotos allowArchive={!queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser} />
              <PermitAttestment />
              <Footer
                canSubmitPackage={canSubmitPackage}
                isLoading={isLoading}
                isFirstSubmission={!queue}
                canSubmitReview={canSubmitReview}
                onSave={handleSave}
                queueType={QueueType.ntpPackage}
                disabled={queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser}
              />
            </Form>
          )}
        </Formik>
      </MainContainer>
    </QueueFlagsProvider>
  );
};
