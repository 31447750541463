import { ObjectId } from 'mongodb';
import { Meta, ProgramType } from '.';

export enum PayeeType {
  organization = 'organization',
  vendor = 'vendor',
}

export interface PayeePlanHistoryEntry {
  date: Date;
  planId: string;
  user: { id: string; name: string };
}

export interface PayeeDocument {
  id?: string;
  externalId: string; // org alias
  type: PayeeType; // this needs to be the enum we create to define org types
  name: string;
  planId: string;
  planHistory?: Array<PayeePlanHistoryEntry>;
  metadata?: Record<string, any>;
  meta?: Meta;
}

export interface PaymentPlanDocument {
  id?: string;
  externalId: string; // LR id
  name: string;
  programType: ProgramType;
  meta?: Meta;
  payments: any[];
}

export interface ProjectDocument {
  id?: string;
  externalId: string; // LR id
  name: string;
  totalCost: number;
  metadata?: Record<string, any>;
  meta?: Meta;
  programType: ProgramType;
  planId?: string;
}

export interface ProjectTransactionRejection {
  date: Date;
  reason: string;
  rejectedBy: { id: string; name: string };
}

export enum ProjectTransactionStatus {
  open = 'open',
  // paid = 'paid',
  pending = 'pending',
  // cancelled?
  // error?
}

export enum PayoutEventType {
  milestone = 'milestone',
  materials = 'materials',
}

export enum PayoutEvent {
  noticeToProceed = 'noticeToProceed',
  noticeToProceedPlus = 'noticeToProceedPlus', // notice to proceed and permit submitted
  estimate = 'estimate',
  invoice = 'invoice',
  installSubmitted = 'installSubmitted',
  installApproved = 'installApproved',
  activationApproved = 'activationApproved',
}

export const PayoutEventMap: Record<ProgramType, Array<{ event: PayoutEvent; name: string; order: number }>> = {
  [ProgramType.solar]: [
    { event: PayoutEvent.noticeToProceed, name: 'NTP', order: 1 },
    { event: PayoutEvent.noticeToProceedPlus, name: 'NTP+', order: 2 },
    { event: PayoutEvent.installSubmitted, name: 'Install Submitted', order: 3 },
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 4 },
    { event: PayoutEvent.activationApproved, name: 'Activation Approved', order: 5 },
  ],
  [ProgramType.doePr]: [
    { event: PayoutEvent.noticeToProceed, name: 'NTP', order: 1 },
    { event: PayoutEvent.noticeToProceedPlus, name: 'NTP+', order: 2 },
    { event: PayoutEvent.installSubmitted, name: 'Install Submitted', order: 3 },
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 4 },
    { event: PayoutEvent.activationApproved, name: 'Activation Approved', order: 5 },
  ],
  [ProgramType.hvac]: [],
};

export interface ProjectTransactionDocument {
  id?: string;
  projectId: ObjectId;
  projectExternalId: string;
  payeeId: ObjectId;
  payeeType: PayeeType;
  programType: ProgramType;
  amount: number;
  calculation: Record<string, number> | { asString: string };
  batchId?: ObjectId;
  planId: ObjectId;
  rejections?: Array<ProjectTransactionRejection>;
  status: ProjectTransactionStatus;
  meta?: Meta;
  prePayment: boolean;
  eventType: PayoutEventType;
  event: PayoutEvent; // should restrict by payout type
}

export interface BatchReview {
  status: 'approved' | 'rejected';
  user: { id: string; name: string };
  date: Date;
  changedFrom: any;
  changedTo: any;
}

export enum PaymentTransactionProvider {
  bofa = 'bofa',
}

export enum bofaTransactionStatus { // this should come from the bofa payments package in the future
  processingByBank = 'Processing By Bank',
  rejected = 'Rejected',
  settlementComplete = 'Settlement Complete',
  approved = 'Approved',
  cancelled = 'Cancelled',
  receivedByBank = 'Received By Bank',
}

export interface BatchDocument {
  id: string;
  payeeId: ObjectId;
  payeeExternalId: string;
  bankTransaction: {
    provider: PaymentTransactionProvider;
    id: string;
    status: bofaTransactionStatus;
    statusHistory: Array<{ id: string; status: bofaTransactionStatus; statusDate: Date }>;
  };
  review?: BatchReview;
  reviewHistory?: Array<BatchReview>;
  projectTransactionsStartDate: Date;
  projectTransactionsEndDate: Date;
  meta?: Meta;
}

export enum PayoutsCollection {
  payees = 'payees',
  projects = 'projects',
  projectTransactions = 'projectTransactions',
  paymentPlans = 'paymentPlans',
  batches = 'batches',
}
