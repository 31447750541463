import { Box, Button, Card, Icon, IconName, Spinner, useBreakpoint } from '@palmetto/palmetto-components';
import RequirePermissions from '../../auth/requirePermissions';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import { OrganizationNode } from '../../../types/Organizations';
import { PropertyValue } from '../../PropertyValue';
import { OrganizationStateLicenses } from './OrganizationStateLicenses';
import { OrganizationAuthorizedSigners } from './OrganizationAuthorizedSigners';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OrganizationTypeLabels, ProgramType, ProgramTypeLabels } from 'types';

interface ViewPropertiesProps {
  organization: OrganizationNode | undefined;
}

const ProgramTypeIconMap: Record<ProgramType, IconName> = {
  hvac: 'heat-pump',
  solar: 'sun',
  doePr: 'lightbulb', // TODO: replace with icon when we have it
};

const ViewProperties = ({ organization }: ViewPropertiesProps) => {
  const { hvacProgramType = false, doePrProgramType = false } = useFlags();

  return (
    <Box padding="0 lg lg lg" gap="xs">
      <PropertyValue label="Name" value={organization?.name || ''} />
      <PropertyValue label="Alias" value={organization?.alias || ''} />
      <PropertyValue
        label="Parent Organization Alias"
        value={organization?.parentAlias ? organization.parentAlias : 'None'}
      />
      {!!organization?.pricingTierId && (
        <RequirePalmettoFinanceUser>
          <RequirePermissions permissions={['admin']}>
            <PropertyValue label="Pricing Tier" value={organization?.pricingTierId || ''} />
          </RequirePermissions>
        </RequirePalmettoFinanceUser>
      )}
      <PropertyValue label="Visible Organizations" value={organization?.visibleOrganizationAliases.join(', ')} />
      <PropertyValue label="Insurance Company Name" value={organization?.insuranceCompanyName || ''} />

      <PropertyValue
        label="Insurance Company Phone Number"
        value={organization?.insuranceCompanyPhoneNumber || ''}
      />

      <PropertyValue label="Company Phone Number" value={organization?.phoneNumber || ''} />
      <PropertyValue label="Company Email" value={organization?.email || ''} />
      <PropertyValue label="Federal Employer Id" value={organization?.federalEmployerId || ''} />
      <PropertyValue label="Address" value={organization?.address || ''} />
      <PropertyValue
        label="City, State Zip"
        value={
          organization?.city && organization?.state && organization?.zip
            ? `${organization.city}, ${organization.state} ${organization.zip}`
            : ''
        }
      />
      {(hvacProgramType || doePrProgramType) && (
        <Box margin="lg 0 0 0" childGap="md">
          <Box as="h4" fontSize="md" fontWeight="medium">
            Program Types
          </Box>
          <Box direction="row" childGap="xl" alignItems="center">
            {organization?.programTypes?.map((programType) => (
              <Box key={programType} direction="row" childGap="sm" color="grey-400" alignItems="center">
                <Icon name={ProgramTypeIconMap[programType] as IconName} size="2xl" />
                <Box>{ProgramTypeLabels[programType]}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Box margin="lg 0 0 0" childGap="md">
        <Box as="h4" fontSize="md" fontWeight="medium">
          Organization Types
        </Box>
        <Box direction="row" childGap="xl" alignItems="center">
          {organization?.organizationTypes?.map((organizationType) => (
            <Box key={organizationType} direction="row" childGap="sm" color="grey-400" alignItems="center">
              <Box>{OrganizationTypeLabels[organizationType]}</Box>
            </Box>
          ))}
        </Box>
      </Box>
      <OrganizationAuthorizedSigners organization={organization} />
      <OrganizationStateLicenses organization={organization} />
    </Box>
  );
};

interface OrganizationPropertiesProps {
  organization: OrganizationNode | undefined;
  isLoading?: boolean;
  className?: string;
}

const OrganizationProperties = ({ className, organization, isLoading }: OrganizationPropertiesProps) => {
  const { isPhone } = useBreakpoint();

  return (
    <Card className={className}>
      <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
        <Box as="h3" fontWeight="medium" fontSize="md">
          Details
        </Box>
        <RequirePermissions permissions={['admin']}>
          {organization && (
            <Button
              as="a"
              href={`/settings/organizations/${organization.alias}/edit`}
              variant="primary"
              tone="neutral"
              size="sm"
              aria-label="Edit Organization"
              iconPrefix="pencil"
            >
              {!isPhone && 'Edit'}
            </Button>
          )}
        </RequirePermissions>
      </Box>
      {isLoading && (
        <Box padding="0 lg lg lg">
          <Spinner />
        </Box>
      )}
      {!isLoading && !organization && <Box padding="0 lg lg lg">Organization not found</Box>}
      {!isLoading && organization && <ViewProperties organization={organization} />}
    </Card>
  );
};

export default OrganizationProperties;
