export enum QuoteStatus {
  active = 'active',
  contracted = 'contracted',
  voided = 'voided',
}

export enum AdderType {
  arbitrageBattery = 'arbitrageBattery',
  backupBattery = 'backupBattery',
  backupBatteryKwh = 'backupBatteryKwh',
  backupBatteryPriceCap = 'backupBatteryPriceCap',
  electricalUpgrade = 'electricalUpgrade',
  solarReadiness = 'solarReadiness',
}

export const addersTitle = {
  arbitrageBattery: 'Arbitrage Battery',
  backupBattery: 'Backup Battery',
  electricalUpgrade: 'Electrical Upgrade',
  solarReadiness: 'Solar Readiness',
};

type Address = {
  address1: string;
  city: string;
  state: string;
  zip: string;
};

type Applicant = {
  type: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address: Address;
};

type Requirement = {
  isRequired: boolean;
  name: string;
  order: number;
  status: string;
  type: string;
  completedAt?: string;
  requirements: Requirement[];
};

type Milestone = {
  completedAt: string;
  completed: boolean;
  name: string;
  order: number;
  status: string;
  type: string;
  requirements: Requirement[];
};

type Utility = {
  lseId: number;
  utilityName: string;
};

type SystemDetails = {
  systemFirstYearProductionKwh: number;
  systemSizeKw: number;
  inverterModel: string;
  inverterManufacturer: string;
  totalPanelCount: number;
  panelManufacturer: string;
  panelModel: string;
};

type Coordinates = {
  lat: number;
  lon: number;
};

export type Quotes = {
  id: string;
  organizationId: string;
  externalReference: string;
  friendlyName: string;
  primaryApplicantName: string;
  coordinates: Coordinates;
  address: Address;
  applicants: Applicant[];
  language: string;
  utility: Utility;
  systemDetails: SystemDetails;
  salesRepName: string;
  status: string;
  monitoringSiteId: string;
  currentMilestone: Milestone;
  milestones: Milestone[];
};
