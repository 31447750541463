import { Badge, Box, Button, FileUpload, toast, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDocumentsQuery, useUploadDocumentMutation } from '../../services/documents';
import { Gallery } from '../Gallery/Gallery';
import { RejectionModal } from '../Reject/RejectionModal';
import RequirePermissions from '../auth/requirePermissions';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import { useUpdateTaskStatusMutation } from '../../services/tasks';
import { isErrorWithData } from '../../services/helpers';
import RejectionAlert from '../Reject/RejectionAlert';
import { ActivationPackageActions, ActivationPhotoSectionType } from '../../types/ActivationPackage';
import { BaseRejectionReasons } from '../../types/BasePackageTypes';

type ActivationPhotoItemProps = {
  section: ActivationPhotoSectionType;
  task: any;
  showUploadButton?: boolean;
};

export const ActivationPhotoItem = ({ section, task, showUploadButton = true }: ActivationPhotoItemProps) => {
  const { id, action } = useParams<{ id: any; action: ActivationPackageActions }>();
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const { data: documents = [] } = useGetDocumentsQuery({
    accountId: id,
    documentTypeFilter: [section.id],
  });
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();
  const { isPhone } = useBreakpoint();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const rejectionReasons = useMemo(() => {
    const reasons = Object.keys(BaseRejectionReasons).map((baseReason) => ({
      label: BaseRejectionReasons[baseReason as keyof typeof BaseRejectionReasons],
      value: baseReason,
    }));
    return reasons;
  }, []);

  const handleFileUpload = async (event: any) => {
    const files = event.target?.files;
    if (!!files) {
      const data = new FormData();
      data.append('type', section.id);

      for (let i = 0; i < files.length; i++) {
        data.append(`files[${i}]`, files[i]);
      }

      try {
        await uploadDocument({ accountId: id, formData: data, invalidateAllTags: false }).unwrap();
        toast.success(`${section.title} photos uploaded`);
      } catch (e: any) {
        if (e.status === 413 || e.originalStatus === 413) {
          toast.error('File size too large. Please upload files smaller than 125MB.');
        } else if (isErrorWithData(e)) {
          toast.error(e.data.message);
        } else {
          toast.error('An error occurred while uploading documents');
        }
      }
    }
  };

  const updateAdminTask = async (value: {
    status: 'completed' | 'rejected';
    rejectionNote?: string;
    rejectionReasons?: string[];
  }) => {
    try {
      await updateTaskStatus({ accountId: id, taskId: task.id, status: value }).unwrap();
      toast.success('Task updated');
    } catch (e: any) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating task: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating task');
      }
    }
  };

  const handleRejectModal = async (value: {
    rejectionReason: { value: string; label: string }[];
    rejectionNote: string;
  }) => {
    const rejectionReasons = value.rejectionReason.map((reason) => reason.value);
    await updateAdminTask({
      status: 'rejected',
      rejectionNote: value.rejectionNote,
      rejectionReasons,
    });
    closeModal();
  };

  const handleApproval = async () => {
    updateAdminTask({ status: 'completed' });
  };

  return (
    <>
      <RejectionModal
        title={`Reject ${section.title}`}
        rejectionReasons={rejectionReasons}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        onSubmit={handleRejectModal}
      />

      <Box width="100" padding="lg" borderWidth="0 0 xs 0" borderColor="separator" childGap="lg">
        <Box direction="row" width="100" justifyContent="space-between" alignItems="center" childGap="lg">
          <Box flex="auto" childGap="2xs">
            <Box direction="row" alignItems="center" childGap="xs">
              <Box as="h3" fontWeight="medium" fontSize="md">
                {section.title}
              </Box>
              <Box fontSize="sm" color="body-secondary">
                {documents.length} {documents.length === 1 ? 'file' : 'files'}
              </Box>
            </Box>
            {section.description && (
              <Box display={{ base: 'none', tablet: 'block' }} as="p" fontWeight="regular" fontSize="sm">
                {section.description}
              </Box>
            )}
          </Box>

          <Box alignContent="flex-end" minWidth="25" alignItems="flex-end" childGap="xs">
            {task && task?.status !== 'ready' && (
              <Badge
                message={task.status === 'completed' ? 'Approved' : task.status}
                style={{ width: 'fit-content' }}
                variant={task.status === 'completed' ? 'success' : 'danger'}
              />
            )}
            {showUploadButton && (
              <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
                <FileUpload
                  labelText="Upload"
                  id={`upload-${section.id}-photos`}
                  name={`${section.id}-files`}
                  accept="image/*,.pdf"
                  size="sm"
                  onChange={handleFileUpload}
                  multiple
                  buttonText={!isPhone ? 'Upload' : null}
                  onClick={(event: any) => event.stopPropagation()}
                  isDisabled={isUploadingDocument}
                />
              </RequirePermissions>
            )}
          </Box>
        </Box>
        {task && task?.status === 'rejected' && (
          <RejectionAlert task={task} rejectionReasons={BaseRejectionReasons} />
        )}
        {documents.length > 0 && <Gallery documents={documents} useLightbox />}
        {documents.length > 0 && action === ActivationPackageActions.review ? (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
              <Box direction="row" justifyContent="flex-end" childGap="xs">
                {task && task?.status !== 'completed' && (
                  <Button variant="primary" size="sm" onClick={handleApproval}>
                    Approve
                  </Button>
                )}
                <Button onClick={openModal} size="sm" variant="primary" tone="danger">
                  Reject
                </Button>
              </Box>
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ) : null}
      </Box>
    </>
  );
};
