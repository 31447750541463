import { ProgramType, PaymentPlan as PaymentPlanType, PayoutEvent, PayoutEventMap } from '@/../../types/src';
import { getOrgNameFromAlias } from '@/helpers/getOrgNameFromAlias';
import { Box, FormikRadioGroup, Table } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface PaymentPlanTableProps {
  paymentPlan?: PaymentPlanType;
  scheduleOwnerAlias: string;
  inherited: boolean | undefined;
  programType: ProgramType;
  selectable: boolean;
}

const PaymentPlanTable = ({
  paymentPlan,
  scheduleOwnerAlias,
  inherited,
  programType,
  selectable,
}: PaymentPlanTableProps) => {
  const percentMap = paymentPlan?.paymentSchedule.reduce(
    (acc, setting) => {
      acc[setting.event] = {
        event: setting.event,
        paymentPercent: setting.paymentPercent,
      };
      return acc;
    },
    {} as Record<PayoutEvent, any>,
  );

  const PaymentScheduleIdInput = ({ id }: { id: string }) => {
    const { setFieldValue, values, setErrors, isSubmitting } = useFormikContext<any>();

    const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('paymentScheduleId', event.target.value);
    };

    useEffect(() => {
      if (values.paymentScheduleId) {
        setErrors({ paymentScheduleId: undefined });
      }
    }, [setErrors, values.paymentScheduleId]);

    return (
      <Field
        name="paymentScheduleId"
        id="paymentScheduleId"
        component={FormikRadioGroup}
        options={[{ value: id, label: '', id: id }]}
        isDisabled={isSubmitting}
      />
    );
  };

  const getPaymentScheduleColumns = () => {
    const baseColumns = [
      {
        heading: '',
        dataKey: 'name',
        render: (cell?: any) => (
          <Box fontWeight="medium" as="span" display="inline">
            {cell}
          </Box>
        ),
      },
    ];

    const dynamicColumns = PayoutEventMap[programType]
      .sort((a, b) => a.order - b.order)
      .map((entry) => ({
        heading: entry?.name,
        dataKey: entry.event,
        render: (_cell?: any, _row?: any, _rowIndex?: number) => (
          <Box fontWeight="medium" as="span" display="inline">
            {percentMap?.[entry.event]?.paymentPercent ? `${percentMap[entry.event].paymentPercent * 100}%` : '-'}
          </Box>
        ),
      }));

    if (selectable) {
      baseColumns.unshift({
        heading: '',
        dataKey: 'id',
        render: (cell?: any) => <PaymentScheduleIdInput id={cell} />,
      });
    }

    return [...baseColumns, ...dynamicColumns];
  };

  return (
    <Box padding="0 lg lg lg" childGap="xs">
      <Box
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderWidth="0 0 xs 0"
        borderColor="separator"
        padding="0 0 xs 0"
      >
        {inherited && (
          <>
            <Box fontWeight="medium">Inherited From</Box>
            <Box direction="row" childGap="xs" alignItems="center">
              <Link style={{ color: 'green' }} to={`/settings/organizations/${scheduleOwnerAlias}/payments`}>
                {getOrgNameFromAlias(scheduleOwnerAlias)}
              </Link>
            </Box>
          </>
        )}
      </Box>
      {paymentPlan && !inherited && (
        <Table rowKey="paymentSchedule" columns={getPaymentScheduleColumns()} rows={[paymentPlan]} />
      )}
    </Box>
  );
};

export default PaymentPlanTable;
