import { NTPPackage, DeepPartial } from 'types';
import { api } from './api';

export const ntpPackageApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveNTPPackage: build.mutation<any, { accountId: string; ntpPackage: DeepPartial<NTPPackage> }>({
      query: ({ accountId, ntpPackage }) => ({
        url: `accounts/${accountId}/ntp-package/save`,
        method: 'POST',
        body: { ...ntpPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Accounts', id: arg?.accountId }],
    }),
    submitNTPPackage: build.mutation<any, { accountId: string; ntpPackage: NTPPackage }>({
      query: ({ accountId, ntpPackage }) => ({
        url: `accounts/${accountId}/ntp-package/submit`,
        method: 'POST',
        body: { ...ntpPackage },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Accounts', id: arg?.accountId },
        { type: 'Queues', accountId: arg?.accountId },
      ],
    }),
  }),
});

export const { useSaveNTPPackageMutation, useSubmitNTPPackageMutation } = ntpPackageApi;
