import { DocumentStatus, DocumentDocument, AllDocumentTypes } from 'types';
import { DocumentSortField } from 'types';
import { api } from './api';

const buildQueryUrl = (baseUrl: string, filters: Record<string, any[] | DocumentSortField | undefined>) => {
  const filterArr = Object.keys(filters)
    .map((filterKey) => {
      if (filters[filterKey]) {
        return `${filterKey}=${filters[filterKey]}`;
      }
      return null;
    })
    .filter((filterStr) => !!filterStr);

  if (filterArr.length) {
    return `${baseUrl}?${filterArr.join('&')}`;
  }

  return baseUrl;
};

export const documentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDocuments: build.query<
      DocumentDocument[],
      {
        accountId: string;
        documentTypeFilter?: Array<AllDocumentTypes>;
        documentStatusFilter?: Array<DocumentStatus>;
        sort?: DocumentSortField;
      }
    >({
      query: ({ accountId, documentTypeFilter, documentStatusFilter, sort }) => {
        const url = buildQueryUrl(`accounts/${accountId}/documents`, {
          filter: documentTypeFilter,
          status: documentStatusFilter,
          sort,
        });
        return {
          url,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'DocumentDocument' as const, id: id?.toString() })),
              { type: 'DocumentDocument', id: 'LIST' },
            ]
          : [{ type: 'DocumentDocument', id: 'LIST' }],
    }),
    getDocumentByAccountAndDocumentId: build.query<DocumentDocument, { accountId: string; documentId: string }>({
      query: ({ accountId, documentId }) => `accounts/${accountId}/documents/${documentId}`,
    }),
    getDocumentsByMultipleRequests: build.query<
      DocumentDocument[],
      {
        accountId: string;
        apiFilters: Array<{
          documentTypeFilter?: Array<AllDocumentTypes>;
          documentStatusFilter?: Array<DocumentStatus>;
          sort?: DocumentSortField;
        }>;
      }
    >({
      queryFn: ({ accountId, apiFilters }, _queryApi, _extraOptions, fetchWithBQ) => {
        const documentPromises = apiFilters.map(({ documentTypeFilter, documentStatusFilter, sort }) =>
          fetchWithBQ(
            buildQueryUrl(`accounts/${accountId}/documents`, {
              filter: documentTypeFilter,
              status: documentStatusFilter,
              sort,
            }),
          ),
        );

        return Promise.all(documentPromises).then((documentApiResults: any[]) => {
          const documents: DocumentDocument[] = [];
          documentApiResults?.forEach((result) => documents.push(...result.data));
          return { data: documents };
        });
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'DocumentDocument' as const, id: id?.toString() })),
              { type: 'DocumentDocument', id: 'LIST' },
            ]
          : [{ type: 'DocumentDocument', id: 'LIST' }],
    }),
    updateDocumentStatus: build.mutation<
      DocumentDocument,
      { id: string; accountId: string; status: DocumentStatus; rejectionReasons?: string[]; rejectionNote?: string }
    >({
      query: ({ id, accountId, status, rejectionNote, rejectionReasons }) => ({
        url: `accounts/${accountId}/documents/${id}`,
        method: 'PATCH',
        body: { status, rejectionNote, rejectionReasons },
      }),
      invalidatesTags: (_result, _error, { id, accountId }) => [
        { type: 'DocumentDocument', id },
        { type: 'DocumentDocument', id: 'REJECTED' },
        { type: 'AccountMilestones', id: 'AccountMilestones' },
        { type: 'AccountProgress', id: 'AccountProgress' },
        { type: 'Applications', id: 'LIST' },
        { type: 'QueueTasks', id: 'LIST' },
        { type: 'Queues', accountId: accountId },
        { type: 'Quotes', id: 'LIST' },
        { type: 'Tasks', id: 'LIST' },
        { type: 'AccountMilestones', id: accountId },
        { type: 'Accounts', id: accountId },
      ],
    }),
    archiveDocument: build.mutation<Document, { id: string; accountId: string; invalidateAllTags: boolean }>({
      query: ({ id, accountId }) => ({
        url: `accounts/${accountId}/documents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { accountId, id, invalidateAllTags }) =>
        invalidateAllTags
          ? [
              { type: 'DocumentDocument', id },
              { type: 'QueueTasks', id: 'LIST' },
              { type: 'Queues', accountId: accountId },
              { type: 'Tasks', id: 'LIST' },
              { type: 'AccountMilestones', id: accountId },
              { type: 'Accounts', id: accountId },
            ]
          : [{ type: 'DocumentDocument', id }],
    }),
    uploadDocument: build.mutation<Document, { accountId: string; formData: FormData; invalidateAllTags: boolean }>({
      query: ({ accountId, formData }) => ({
        url: `accounts/${accountId}/documents`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (_result, _error, { accountId, invalidateAllTags }) =>
        invalidateAllTags
          ? [
              { type: 'DocumentDocument', id: 'LIST' },
              { type: 'QueueTasks', id: 'LIST' },
              { type: 'Queues', accountId: accountId },
              { type: 'Tasks', id: 'LIST' },
              { type: 'AccountMilestones', id: accountId },
              { type: 'Accounts', id: accountId },
            ]
          : [{ type: 'DocumentDocument', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentByAccountAndDocumentIdQuery,
  useLazyGetDocumentsQuery,
  useLazyGetDocumentByAccountAndDocumentIdQuery,
  useUpdateDocumentStatusMutation,
  useArchiveDocumentMutation,
  useUploadDocumentMutation,
  useGetDocumentsByMultipleRequestsQuery,
} = documentsApi;
