import { Box, Column, Table } from '@palmetto/palmetto-components';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ProgramType } from 'types';
import {
  useGetOrganizationDetailsQuery,
  useLazyGetOrgPriceSheetMappingsQuery,
} from '../../../../../services/organizations';
import { TableLoading } from '../../../../TableLoading';
import { mappingsColumnConfig } from '@/components/Pricing/Mappings/MappingTables';

export const Mappings = () => {
  const { alias } = useParams<{ alias: string }>();
  const { data: organization, isLoading: isOrgLoading } = useGetOrganizationDetailsQuery(
    { alias: alias as string },
    { skip: !alias },
  );
  const [trigger, { data, isLoading: isOrgPricingLoading, isFetching }] = useLazyGetOrgPriceSheetMappingsQuery();

  const isLoading = isOrgLoading || isOrgPricingLoading;

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  useEffect(() => {
    if (alias) {
      trigger({ alias, id: id || undefined });
    }
  }, [alias, trigger, id]);

  return (
    <Box margin="0 lg 0 lg">
      {isFetching || isLoading ? (
        <TableLoading rows={12} />
      ) : (
        <Table
          rowKey="id"
          columns={
            [
              ...mappingsColumnConfig.baseStart,
              ...mappingsColumnConfig[organization?.programTypes?.[0] ?? ProgramType.solar],
              ...mappingsColumnConfig.baseEnd,
            ] as Column[]
          }
          rows={data ?? []}
          isLoading={isLoading || isFetching}
          isScrollable={{ x: true }}
          hasStickyHeader
        />
      )}
    </Box>
  );
};
