import { Box, TextLink } from '@palmetto/palmetto-components';
import FormSection from '../Forms/FormSection';
import { PhotosList } from '../MilestonePackagePartials/Documents/PhotosList';
import { ReviewPackageType, reviewPhotoSections } from '../../types/ReviewPackage';

export const HVACProjectPhotos = ({ allowArchive }: { allowArchive?: boolean }) => {
  return (
    <FormSection
      description={
        <Box childGap="xs" color="body-secondary">
          <p>Upload photos to all applicable categories.</p>
          <p>
            Reference {/* TODO: Replace with a less PV-oriented help link when available. */}
            <TextLink
              href="https://help.palmetto.finance/en/articles/8306274-install-photo-documentation"
              target="_blank"
              rel="noreferrer"
            >
              NTP Photo Requirements documentation
            </TextLink>{' '}
            for specifics on submission requirements.
          </p>
        </Box>
      }
      title="Install Location and Existing System Photo Documentation"
    >
      {Object.values(reviewPhotoSections).map((section) => {
        if (section.type === ReviewPackageType.noticeToProceedHVAC)
          return (
            <PhotosList
              key={section.id}
              documentationUrl={section.documentationUrl}
              category={section.id}
              description={section.description}
              title={section.title}
              baseCategory={'Install Location and Existing System Photo Documentation'}
              allowArchive={allowArchive}
            />
          );
        return null;
      })}
    </FormSection>
  );
};
